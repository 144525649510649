import CheckboxSelectAll from 'stimulus-checkbox-select-all'
import axios from "axios"

export default class extends CheckboxSelectAll {
  static targets = ["actions", "count", "action", "idInput", "container", "previewLink", "table"]

  connect() {
    super.connect();

    (function () {
      var thElm;
      var startOffset;
      var startWidths = {};
      var isResizing = false;

      var columnWidth = {};
      if (localStorage.getItem('columnWidth')) {
          columnWidth = JSON.parse(localStorage.getItem('columnWidth'));
      }

      Array.prototype.forEach.call(
          document.querySelectorAll("table thead th"),
          function (th) {
              if (columnWidth && columnWidth[th.dataset.columnResizable]) {
                  th.style.width = columnWidth[th.dataset.columnResizable];
              }
              var grip = document.createElement('div');
              grip.classList.add('table__grip');
              grip.addEventListener('mousedown', function (e) {
                  thElm = th;
                  startOffset = th.offsetWidth - e.pageX;
                  isResizing = true;

                  // Capture initial widths of all columns
                  Array.prototype.forEach.call(document.querySelectorAll("table thead th"), function (th) {
                      startWidths[th.dataset.columnResizable] = th.offsetWidth;
                  });

                  document.addEventListener('mousemove', onMouseMove);
                  document.addEventListener('mouseup', onMouseUp);
              });

              th.appendChild(grip);
          });

      function onMouseMove(e) {
          if (thElm && isResizing) {
              if (startOffset + e.pageX > 40) {
                  var newWidth = startOffset + e.pageX;
                  requestAnimationFrame(() => {
                      thElm.style.width = newWidth + 'px';

                      // Maintain other columns' widths
                      Array.prototype.forEach.call(document.querySelectorAll("table thead th"), function (th) {
                          if (th !== thElm) {
                              th.style.width = startWidths[th.dataset.columnResizable] + 'px';
                          }
                      });
                  });
              }
          }
      }

      function onMouseUp() {
          if (thElm) {
              columnWidth[thElm.dataset.columnResizable] = thElm.style.width;
              localStorage.setItem('columnWidth', JSON.stringify(columnWidth));
              thElm = undefined;
              isResizing = false;

              document.removeEventListener('mousemove', onMouseMove);
              document.removeEventListener('mouseup', onMouseUp);
          }
      }

      // Debounce function
      function debounce(func, wait) {
          let timeout;
          return function () {
              clearTimeout(timeout);
              timeout = setTimeout(() => func.apply(this, arguments), wait);
          };
      }

      // Use requestAnimationFrame for smoother resizing
      function onMouseMoveDebounced(e) {
          requestAnimationFrame(() => onMouseMove(e));
      }

      document.addEventListener('mousemove', debounce(onMouseMoveDebounced, 10));
  })();


    this.values = []
    this.element[this.identifier] = this

    if (this.containerTarget.classList.contains("table-scroll--fixed-column")) {
      if (this.containerTarget.getBoundingClientRect().bottom > window.innerHeight) {
        this.containerTarget.style.height = (window.innerHeight - this.containerTarget.getBoundingClientRect().top) + "px"
      }
    }

    if (this.previewLinkTargets.length > 0) {
      this.previewLinkTargets.forEach(function(elem) {
        elem.closest("tr").addEventListener("click", (e) => {
          if (e.srcElement.tagName != "A" && e.srcElement.tagName != "INPUT") {
            elem.click()
          }
        })
      });
    }

    if (this.containerTarget.classList.contains("table-scroll--fixed-column")) {
      this.containerTarget.style.overflowY = "scroll"
    }
  }

  check () {
    const actionsTarget = this.actionsTarget
    const countTarget = this.countTarget
    let params = new URLSearchParams();

    countTarget.innerHTML = this.checked.length + " selected";

    if (this.checked.length > 0) {
      actionsTarget.style.display = "flex"

    } else {
      actionsTarget.style.display = "none"
    }

    this.checked.forEach((element) => {
      element.closest("tr").classList.add("selected")
    });

    this.unchecked.forEach((element) => {
     element.closest("tr").classList.remove("selected")
    });
  }

  action (e) {
    e.preventDefault()
    var that = this;

    this.runAction(e)
  }

  deleteAction(e) {
    e.preventDefault()
    var confirmation = confirm("Are you sure you want to permanently delete " + this.checked.length + " items?");

    if (confirmation) {
      this.runAction(e)
    }
  }

  runAction (e) {
    var checked_values = this.checked.map(function (el) {
      return el.value
    })

    this.idInputTargets.forEach((el) => {
      el.value = JSON.stringify( checked_values )
    })

    e.target.closest('form').submit()
  }

}
