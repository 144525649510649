import { Controller } from "@hotwired/stimulus"
import * as ClipboardFunctions from "../lib/ClipboardFunctions.js";
import flash from "../lib/flashes.js";

export default class extends Controller {
  static values = { url: String, text: String, isOtp: Boolean }
  static targets = ["icon", "button"]

  copy (event) {
    event.preventDefault()

    const that = this;
    const url = that.urlValue;
    const text = that.textValue;
    const isOtp = that.isOtpValue;

    if (text) {
      ClipboardFunctions.copyText(text)
      this.handleUX()
    } else {
      const xhttp = new XMLHttpRequest();
      xhttp.onreadystatechange = () => {
        if (xhttp.readyState === 4 && xhttp.status === 200) {
          const response = JSON.parse(xhttp.responseText);

          if (isOtp) {
            ClipboardFunctions.copyText(response["otp"])
            flash("OTP copied. " + response["time_left"] + " seconds left", "success")
            this.handleUX()
          } else {
            ClipboardFunctions.copyText(response["password"])
            this.handleUX()
          }
        }
      };

      xhttp.open("GET", url, true);
      xhttp.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
      xhttp.send();
    }

    if (!isOtp) {
      flash("Copied to clipboard", "success")
    }
  }

  handleUX () {

    if (!this.hasIconTarget && !this.hasButtonTarget) {
      return
    }

    if (this.hasButtonTarget) {
      var oldHTML = this.buttonTarget.innerHTML
      this.buttonTarget.innerHTML = "Copied!"
      setTimeout(() => {
        this.buttonTarget.innerHTML = oldHTML
      }, 3000)
    } else {
      // this.iconTarget.style = "display: none"
      var oldHTML = this.iconTarget.innerHTML
      this.iconTarget.innerHTML = "<i class='fas fa-check success'></i>"

      setTimeout(() => {
        this.iconTarget.innerHTML = oldHTML
      }, 3000)
    }

  }
}
