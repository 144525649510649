import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import axios from "axios";
import App from "../components/Commenter.vue";

export default class extends Controller {
  connect() {
    const el = this.element;

    Vue.use({
      install (Vue) {
        Vue.prototype.$api = axios.create({
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
        })
      }
    })
    this.vueInstance = new Vue({
      el,
      render: h => h(App, {
        props: {
          commentable_id: el.dataset.commentableId,
          commentable_type: el.dataset.commentableType,
          url: el.dataset.url,
          can_create: el.dataset.canCreate === "true",
          can_update: el.dataset.canUpdate === "true",
          user_id: el.dataset.userId
        }
      })
    });
  }
}
