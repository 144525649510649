import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["iconInput", "iconColorInput", "colorInput", "preview", "iconPreview", "color", "iconColor", "iconsContainer", "customIcon"]

  connect() {
    this.color = this.colorInputTarget.value
    this.icon = this.iconInputTarget.value
    this.iconColor = this.iconColorInputTarget.value
    this.updateStyles()
    this.loadIcons()
  }

  loadIcons() {
    let icons = [
      "fas fa-circle",
      "fas fa-key",
      "fas fa-envelope",
      "fas fa-laptop",
      "fas fa-newspaper",
      "fas fa-globe",
      "fas fa-industry",
      "fas fa-handshake",
      "fas fa-passport",
      "fas fa-certificate",
      "fas fa-landmark",
      "fab fa-bluetooth",
      "fas fa-file-signature",
      "fas fa-cog",
      "fas fa-phone",
      "fas fa-bug",
      "fas fa-mask",
      "fas fa-user-tie",
      "fas fa-sim-card",
      "fas fa-mobile-alt",
      "fas fa-print",
      "fas fa-file-word",
      "fas fa-file-powerpoint",
      "fas fa-file-code",
      "fas fa-file",
      "fas fa-lightbulb",
      "fas fa-lock",
      "fas fa-link",
      "fas fa-magnet",
      "fas fa-plug",
      "fas fa-wifi",
      "fas fa-shield-alt",
      "fas fa-star",
      "fas fa-spider",
      "fas fa-wrench",
      "fas fa-users",
      "fas fa-user-check",
      "fas fa-user-lock",
      "fas fa-user-shield",
      "fas fa-user-secret",
      "fas fa-video",
      "fas fa-vials",
      "fas fa-terminal",
      "fas fa-hammer",
      "fas fa-house-damage",
      "fas fa-infinity",
      "fas fa-ambulance",
      "fas fa-bell",
      "fas fa-dollar-sign",
      "fas fa-charging-station",
      "fas fa-car",
      "fas fa-camera",
      "fas fa-cloud",
      "fas fa-code",
      "fas fa-cloud-moon",
      "fas fa-comments",
      "fas fa-crown",
      "fas fa-map-marker-alt",
      "fas fa-fax",
      "fas fa-fingerprint",
      "fas fa-gem",
      "fas fa-fill",
      "fas fa-ghost",
      "fas fa-dice-d6",
      "fas fa-network-wired",
      "fas fa-database",
      "fas fa-dumpster",
      "fas fa-fire",
      "fas fa-tasks",
      "fas fa-wave-square",
      "fas fa-warehouse",
      "fas fa-retweet",
      "fas fa-redo-alt",
      "fas fa-screwdriver",
      "fab fa-aws",
      "fab fa-chrome",
      "fab fa-edge",
      "fab fa-firefox",
      "fab fa-apple",
      "fab fa-google",
      "fab fa-microsoft",
      "fab fa-slack",
      "fab fa-wordpress",
      "fab fa-drupal"
    ]
    icons.forEach((icon, index) => {
      const label = document.createElement("label")
      label.innerHTML = `
        <i class="${icon}"></i>
        <input type="radio" name="icon" value="${icon}" ${this.icon === icon ? "checked" : ""} data-action="change->asset-layout-icon#selectIcon">
      `
      this.iconsContainerTarget.appendChild(label)
    })
  }

  updateColor(event) {
    this.color = event.target.value
    this.updateStyles()
  }

  updateIconColor(event) {
    this.iconColor = event.target.value
    this.updateStyles()
  }

  selectIcon(event) {
    this.icon = event.target.value
    this.updateStyles()
  }

  updateIcon(event) {
    this.icon = event.target.value
    this.updateStyles()
  }

  updateStyles() {
    this.colorInputTarget.value = this.color
    this.iconColorInputTarget.value = this.iconColor
    this.iconInputTarget.value = this.icon
    this.previewTarget.style.background = this.color
    this.iconPreviewTarget.className = this.icon
    this.iconPreviewTarget.style.color = this.iconColor
  }
}
