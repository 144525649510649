import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["contents", "tocBlank", "tocContainer"]

  connect() {
    this.isShown = true; // Default value
    this.toc = [];
    this.idCounts = {}; // to track ID usage for uniqueness
    this.getContents();
    this.attemptScroll(); // to scroll to the correct section on page load
  }

  getContents() {
    const headers = this.contentsTarget.querySelectorAll("h1, h2, h3, h4, h5, h6");
    headers.forEach((element) => {
      const readable = element.textContent.trim();
      if (!readable) return;

      const tag = element.tagName;
      // Changed: Use generateUniqueId instead of simple replacement + random number
      // This ensures consistent IDs across page loads and handles duplicates
      // We removed the Math.random() part because:
      // 1. It made IDs inconsistent across page loads
      // 3. It made it impossible to reliably link to specific sections
      let id = this.generateUniqueId(readable);
      element.id = id;
      this.toc.push({ tag: tag, name: readable, id: id });
    });
    this.renderToc();
  }

  // New method to generate unique IDs
  generateUniqueId(text) {
    // Generate a URL-friendly base ID
    let baseId = text.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');

    // Make the ID is unique even if the header text is the same
    if (this.idCounts[baseId]) {
      this.idCounts[baseId]++;
      return `${baseId}-${this.idCounts[baseId]}`;
    } else {
      this.idCounts[baseId] = 1;
      return baseId;
    }
  }

  renderToc() {
    if (this.toc.length == 0) {
      this.tocBlankTarget.style.display = 'block';
    } else {
      this.tocBlankTarget.style.display = 'none';
      const ul = document.createElement('ul');
      this.toc.forEach(t => {
        const li = document.createElement('li');
        li.className = `toc${t.tag}`;
        const a = document.createElement('a');
        a.href = `#${t.id}`;
        a.textContent = t.name;
        li.appendChild(a);
        ul.appendChild(li);
      });
      this.tocContainerTarget.appendChild(ul);
    }
  }

  attemptScroll() {
    if (window.location.hash) {
      const targetId = window.location.hash.slice(1);
      this.scrollToElement(targetId);
    } else {
      this.scrollToTop();
    }
  }

  scrollToElement(targetId, attempts = 0) {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    } else if (attempts < 5) {
      setTimeout(() => this.scrollToElement(targetId, attempts + 1), 500);
    }
  }

  scrollToTop() {
    this.contentsTarget.scrollIntoView({ behavior: 'smooth' });
  }
}
