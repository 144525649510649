import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import App from "../components/FavoriteSorter.vue";
import axios from "axios";

export default class extends Controller {
  connect() {
    const el = this.element;
    Vue.use({
      install (Vue) {
        Vue.prototype.$api = axios.create({
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
        })
      }
    })
    this.vueInstance = new Vue({
      el,
      render: h => h(App, {
        props: {
          fetch_url: el.dataset.fetchUrl,
          update_url: el.dataset.updateUrl
        }
      })
    });
  }
}
