import { Controller } from '@hotwired/stimulus'
import flash from '../lib/flashes';


export default class extends Controller {
  static values = { toastType: {type: String, default: "success"}, message: {type: String, default: ""} };

  connect() {
    if (this.toastTypeValue == "success") {
      flash(this.messageValue, "success")
    } else {
      flash(this.messageValue, "error")
    }
  }
}
