import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import App from "../components/Sidebar.vue";

export default class extends Controller {
  connect() {
    const el = this.element;
    this.vueInstance = new Vue({
      el,
      render: h => h(App, {
        props: {
          items: JSON.parse(el.dataset.items),
          can_add: el.dataset.canAdd === "true",
          no_hide_blank: el.dataset.noHideBlank === "true"
        }
      })
    });
  }
}
