import { Controller } from "@hotwired/stimulus"
import Papa from "papaparse"

export default class extends Controller {
  static targets = ["payload", "fileGroup", "table", "tableBody", "submitButton"]
  static values = {
    defaultGroupName: String,
    data: Array
  }

  filesChange(event) {
    const file = event.target.files[0]
    if (!file) return

    this.fileGroupTarget.style.display = "block"

    Papa.parse(file, {
      header: true,
      transformHeader: (header, index) => index.toString(),
      complete: (results) => {
        this.dataValue = results.data
        this.renderTable()
        this.updatePayload()
        this.toggleSubmitButton()
      },
      skipEmptyLines: true,
    })
  }

  renderTable() {
    this.tableBodyTarget.innerHTML = this.dataValue.map(d => `
      <tr>
        <td>${d["0"] || '<span class="danger">Missing</span>'}</td>
        <td>${d["1"] || '<span class="danger">Missing</span>'}</td>
        <td>${d["2"] || '<span class="danger">Missing</span>'}</td>
        <td>${d["3"] || '<span class="danger">Missing</span>'}</td>
        <td>${d["3"] !== "admin" ? (d["4"] || this.defaultGroupNameValue) : ''}</td>
      </tr>
    `).join('')
  }

  updatePayload() {
    this.payloadTarget.value = JSON.stringify(this.dataValue)
  }

  toggleSubmitButton() {
    this.submitButtonTarget.style.display = this.dataValue.length > 0 ? "inline-block" : "none"
    this.submitButtonTarget.textContent = `Import ${this.dataValue.length} Users`
  }
}
