

require("@rails/ujs").start()
import * as Turbo from "@hotwired/turbo"
import "@hotwired/turbo-rails"
import "./lib/choices_lib.js"
import "./controllers"
import tippy from 'tippy.js';
//Turbo.session.drive = false

import Prism from"./lib/prism.js";
import '@material/mwc-circular-progress';

import TomSelect from 'tom-select';

function mountGlobalScripts () {
  tippy('[data-tippy-content]');
  window.Prism = window.Prism || {};
  window.Prism.manual = true;
  Prism.highlightAll();
  if (document.querySelector(".js-choice")) {
    new TomSelect(".js-choice",{
      create: true,
      sortField: {
        field: "text",
        direction: "asc"
      }
    });
  }
}

// The following prevents the CSRF token from being reset on every Turbo visit
// You can test this by searching in the new datagrid, then trying to turn on dark mode/off dark mode.
// without this - it always results in a 422 error
function getCsrfToken() {
  return document.querySelector('meta[name="csrf-token"]').getAttribute('content');
}

function setCsrfToken(token) {
  var meta = document.createElement('meta');
  meta.name = "csrf-token";
  meta.content = "";
  if (!document.querySelector('meta[name="csrf-token"]')) {
    document.getElementsByTagName('head')[0].appendChild(meta);
    document.querySelector('meta[name="csrf-token"]').setAttribute('content', token);
  }
}

document.addEventListener('turbo:before-fetch-request', function(event) {
  var currentCsrfToken = getCsrfToken();
  event.detail.fetchOptions.headers['X-CSRF-Token'] = currentCsrfToken;
  localStorage.setItem('previousCsrfToken', currentCsrfToken);
});

document.addEventListener('turbo:load', () => {
  mountGlobalScripts();
  var previousCsrfToken = localStorage.getItem('previousCsrfToken');
  if (previousCsrfToken) {
    setCsrfToken(previousCsrfToken);
  }
})

document.addEventListener('DOMContentLoaded', () => {
  mountGlobalScripts();
})

