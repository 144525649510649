import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["securityLevel", "groupId", "roleHeader", "roleCell", "uneditableMessage", "editableContent", "licenseAlert", "roleChoice", "companyGrabber", "groupSelector"]

  connect() {
    this.updateUI()
  }

  updateRole(event) {
    this.securityLevelTarget.value = event.target.value
    this.updateUI()
  }

  updateUI() {
    const securityLevel = this.securityLevelTarget.value

    this.roleHeaderTargets.forEach(header => {
      header.classList.toggle("selected", header.dataset.role === securityLevel)
    })

    this.roleCellTargets.forEach(cell => {
      cell.classList.toggle("selected", cell.dataset.role === securityLevel)
    })

    this.roleChoiceTargets.forEach(choice => {
      choice.classList.toggle("selected", choice.dataset.role === securityLevel)
    })

    this.companyGrabberTarget.style.display = securityLevel === "portal_member" ? "block" : "none"

    const showGroupSelector = !["portal_member", "admin", "super_admin"].includes(securityLevel)
    this.groupSelectorTarget.style.display = showGroupSelector ? "block" : "none"

    // Additional logic for license count, uneditable status, etc. would go here
  }
}
