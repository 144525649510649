<template>
  <div class='writer-wrap'>
    <textarea :id="'editor' + uuid">{{description}}</textarea>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import tinymce from 'tinymce';
import initTiny from "../../lib/tinymce-editor.js";

export default {
  props: ["record_id", "record_type", "dark_mode"],
  computed: {
    ...mapState("procedures", {
      'procedure': 'procedure',
      'openProcessEdit': 'openProcessEdit'
    }),
    description () {
      return this.$store.state.procedures.procedure.procedure_tasks_attributes[this.openProcessEdit].description
    }
  },
  watch: {
    openProcessEdit (newValue) {
      this.$forceUpdate();
    },
  },
  mounted () {
    this.uuid = this.generateUuidv4()
    this.$nextTick(() => {
      this.$forceUpdate();
    })
  },
  updated () {
    this.loadEditor()
  },
  methods: {
    generateUuidv4 () {
      return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
      );
    },
    loadEditor () {
      var that = this;
      that.index = this.openProcessEdit

      var editorname = 'editor' + that.uuid

      initTiny(that, '#' + editorname, null, false)
    }
  },
  data() {
    return {
      editor: null,
      uuid: "",
      index: ""
    }
  }
}
</script>

<style lang='scss'>
</style>

