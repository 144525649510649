<template>
  <div class='asset-sidebar__group' @keydown.esc="close">
    <header>
      <h2><widget-toggler name='Relater'>Related Items ({{total_size}})</widget-toggler></h2>
      <a class=''  v-show='!add && can' href='#' @click.prevent.stop="startAdd">
        <i class='fas fa-plus'></i> Add
      </a>
      <a href='#' v-show='add' @click.prevent.stop="add = false">
        <i class='fas fa-times'></i> Close
      </a>
    </header>

    <Teleport to="body">
      <div class='manage_modal__wrapper' v-show='add'>
        <div class='manage_modal'>
          <header>
            <h1><i class='far fa-link icon'></i>Relate</h1>
            <a href='#' class='button button--secondary' @click.prevent.stop='add = false'>Close</a>
          </header>
          <div class="relater">
            <div class="relater__side">
              <div class="relater__side-header">
                <h1>Create Related Items</h1>
              </div>
              <div class="relater__side-content">
                <div class="relater__toolbar">
                  <div class="search-input">
                    <i class="far fa-search"></i>
                    <input ref='searchInput' id="relaterSearchInput" type='search'
                      @click="showSearch = true"
                      @input="onInput"
                      @keydown.esc="showSearch = false"
                      v-model='q' placeholder="Search to add...">
                  </div>
                  <select @change.prevent.stop="onInput" v-model='isType'>
                    <option value='Company'>{{structure_name}}</option>
                    <option value='Asset'>Custom Asset</option>
                    <option>Article</option>
                    <option>Password</option>
                    <option>Website</option>
                  </select>
                  <div class="relater__toolbar-options">
                    <label v-if='company_id && (isType != "Company")'><input @change.prevent.stop="onInput" v-model='only_company' type='checkbox'>Limit to&nbsp;<b>{{company_name}}</b></label>
                    <label><input @change.prevent.stop="onInput" v-model='include_archived' type='checkbox'>Museum</label>
                  </div>
                </div>
                <div class='relater__results'>
                  <div class='relater__result' v-for='(result, index) in results'>
                    <a href='#' class='button button--small button--success' @click.prevent.stop="addRelation(result.id, result.object_type, index)">
                      <i class='fas fa-plus'></i> Add
                    </a>
                    <p><a :href='result.url' target="_blank">{{result.name}}</a><small v-if='result.asset_type'>{{result.asset_type}} / {{result.company_name}}</small></p>
                  </div>
                </div>
              </div>
            </div><!--relater-side-->
            <div class="relater__side">
              <div class="relater__side-header">
                <h1>Existing Related Items</h1>
              </div>
              <div class="relater__side-content">
                <div class='relater__results'>
                  <template v-for='r in relations' v-if='r.items.length > 0'>
                    <div class='relater__result-header'>
                      <h1><i :class='r.icon'></i>{{r.name}} ({{r.items.length}})</h1>
                    </div>
                    <div class='relater__result' v-for="relation in r.items">
                      <a href='#' class='button button--small button--danger' @click.prevent.stop="removeRelation(relation.id, relation.object_type)">
                        <i class='fas fa-minus'></i> Remove
                      </a>
                      <p><a :href='relation.url' target="_blank">{{relation.name}}</a><small v-if='relation.asset_type'>{{relation.asset_type}} / {{relation.company_name}}</small></p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
    <div class="asset-sidebar__group-content asset-sidebar__group-content--Relater">
      <div class='asset-sidebar__box'>
        <template v-for='r in relations' v-if='r.items.length > 0'>
          <header>
            <h1 v-if="!r.linked"><i :class='r.icon'></i>{{r.name}}</h1>
            <h1 v-if="r.linked"><i :class='r.icon'></i>Tagged in {{r.name}}</h1>
          </header>
          <section class='indented' v-for="(relation, index) in r.items">
            <template v-if='isEdit && (editIndex === relation.id)'>
              <h3><a>{{relation.name}}</a></h3>
              <p class='asset-sidebar__box__description' v-if='relation.asset_type'>{{relation.asset_type}} / {{relation.company_name}}</p>
              <form @submit.prevent.stop='updateDescription(relation.id)' class='asset-sidebar__form asset-sidebar__form--full'>
                <input type='text' v-model='newDescription' :id='"relaterDescInput" + relation.id' placeholder="Describe relation...">
                <div class='button-group'>
                  <a href='#' @click.prevent.stop='updateDescription(relation.id)' class='button button--small button--primary'>Save</a>
                  <a href='#' @click.prevent.stop='resetEdit' class='button button--small button--secondary'>Cancel</a>
                </div>
              </form>
            </template>

            <template v-if='editIndex != relation.id'>
              <h3>
                <a :href='relation.url'>{{relation.name}} <i v-show='relation.discarded_at' title='Archived' style='opacity: .5' class='fas fa-university'></i>
                <div v-if='relation.description' class='asset-sidebar__box__description'>{{relation.description}}</div>
                </a>
              </h3>
              <p class='asset-sidebar__box__description' v-if='relation.asset_type'>{{relation.asset_type}} / {{relation.company_name}}</p>
              <div v-if='can && relation.id && !r.linked' class='button-group button-group--condensed'>
                <a href='' @click.prevent.stop='startEdit(relation.id, relation.description)' class='button button--plain'><i class="fas fa-edit"></i></a>
                <a href='#' @click.prevent="removeRelation(relation.id, index)" class='button button--sidebar button--plain'><i class='fas fa-trash'></i></a>
              </div>
            </template>
          </section>
        </template>
        <slot></slot>
      </div>
      <div class='asset-sidebar__empty' v-if="total_size == 0">
        <p>Add related items to link important information together</p>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios');
import Teleport from 'vue2-teleport';
import WidgetToggler from "./WidgetToggler.vue"
import flash from '../lib/flashes';
export default {
  props: ["id", "type", "can", "company_name", "company_id", "default", "structure_name"],
  components: {
    Teleport,
    WidgetToggler
  },
  mounted () {
    var that = this;
    that.getRelations()
  },
  methods: {
    getRelations () {
      var that = this;
      that.$api.get(`/relations/get_relations?id=${that.id}&type=${that.type}`)
      .then(function(response) {
        that.relations = response.data.relations
        that.total_size = response.data.total_size
      }).catch(error => {
        flash("Something went wrong!", 'error')
      })
    },
    startAdd () {
      this.add = true;
      this.resetEdit()
      this.$nextTick(() => document.querySelector("#relaterSearchInput").focus())
    },
    startEdit (id, description) {
      this.isEdit = true;
      this.editIndex = id;
      this.newDescription = description;

      this.$nextTick(() => document.querySelector("#relaterDescInput" + id).focus())
    },
    updateDescription (id) {
      var that = this;
      that.$api.put(`/relations/${id}`, {
        relation: { description: that.newDescription }
      }).then(function (response) {
        that.getRelations();
        that.resetEdit();
        flash("Relation Updated!", 'success');
      }).catch(error => {
        console.log(error);
        flash("Something went wrong!", 'error');
      });
    },
    removeRelation (id) {
      var that = this;
      if(confirm('Are you sure you want to delete this relation?')) {
        that.$api.delete(`/relations/${id}`).then(function (response) {
          that.getRelations()
          that.resetEdit()
          that.onInput()
          flash("Relation removed!", 'success')
        }).catch(error => {
          console.log(error)
          flash("Something went wrong!", 'error')
        })
      }
    },
    onInput () {

      var that = this;

      axios.get('/searches', {
        params: {
          q: that.q,
          is_relations_search: true,
          type: that.isType,
          company: that.only_company ? that.company_id : "",
          include_archived: that.include_archived,
          disable_type: that.type,
          disable_id: that.id
        }
      })
      .then(function (response) {
        that.results = response.data;
      })
    },
    addRelation (id,type, index) {
      var that = this;
      that.$api.post('/relations', {
        relation: {
          fromable_type: that.type,
          fromable_id: that.id,
          toable_type: type,
          toable_id: id
        }
      })
      .then(function (response) {
        that.getRelations()
        that.results.splice(index, 1);
        that.showSearch = false;
        flash("Relation added!", 'success')
      }).catch(error => {
        console.log(error)
        flash("Something went wrong!", 'error')
      })
    },
    resetEdit () {
      this.isEdit = false;
      this.editIndex = -1;
      this.newDescription = ""
    },
  },
  data: function () {
    return {
      relations: [],
      q: "",
      isType: this.default,
      results: [],
      add: false,
      only_company: true,
      showSearch: false,
      newDescription: "",
      include_archived: false,
      isEdit: false,
      editIndex: -1,
      total_size: 0
    }
  }
}
</script>
