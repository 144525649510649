


<template>

  <div class='magic-dash' v-if='data.length > 0'>

    <div class='nasa__block'>
      <header class='nasa__block-header'>
        <h1><i class="fas fa-bolt icon"></i>Magic Dash</h1>
      </header>
      <main>
        <div class='fast-facts__section' v-if='data.length > 0'>
            <a href='' v-if='openItem != 0' class='custom-fast-facts__exit' @click.prevent.stop='close'><i class='fas fa-arrow-left'></i>Back</a>
            <div :class='cffClass(activeItem)' v-if='openItem != 0'>
              <div class='custom-fast-fact__header'>
                  <template v-if='activeItem.image_url'>
                    <img :src='activeItem.image_url' class='custom-fast-fact__img'/>
                  </template>
                  <template v-if='!activeItem.image_url'>
                    <i v-if='activeItem.icon' :class='activeItem.icon'></i>
                    <i v-if='!activeItem.icon' class='fas fa-info-circle'></i>
                  </template>
                  <h1>{{activeItem.title}}</h1>
              </div>
              <div class='custom-fast-fact__opened' v-html='activeItem.content'>
              </div>
            </div>
            <div class='custom-fast-facts'>
              <div :class='cffClass(d)' v-for='(d, index) in data' v-if='openItem === 0'>
                <div class='custom-fast-fact__header'>

                  <template v-if='d.image_url'>
                    <img :src='d.image_url' class='custom-fast-fact__img'/>
                  </template>
                  <template v-if='!d.image_url'>
                    <i v-if='d.icon' :class='d.icon'></i>
                    <i v-if='!d.icon' class='fas fa-info-circle'></i>
                  </template>
                  <h1>{{d.title}}</h1>
                </div>
                <div class='custom-fast-fact__content'>
                  <p v-html='d.message'></p>
                </div>
                <div v-if='d.content' class='custom-fast-fact__footer'>
                  <a href='#' @click.prevent.stop='open(d.id, index)'>Open <i class='fas fa-arrow-right'></i></a>
                </div>
                <div v-if='d.content_link' class='custom-fast-fact__footer'>
                  <a :href='d.content_link' target="_blank">Open Link <i class='fas fa-external-link-alt'></i></a>
                </div>
              </div>
            </div>
          </div>
      </main>
    </div>
  </div>

</template>

<script>
const axios = require('axios');
import vClickOutside from 'v-click-outside'
export default {
  props: ["data"],
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    cffClass (d) {
      return `custom-fast-fact custom-fast-fact--${d.shade}`;
    },
    open (id, index) {
      this.openIndex = index;
      this.openItem = id;
    },
    close () {
      this.openItem = 0;
    },
    toggle () {
      this.isOpened = !this.isOpened
    }
  },
  data: function () {
    return {
      openItem: 0,
      openIndex: -1,
      isOpened: false,
      isShown: false
    }
  },
  computed: {
    activeItem () {
      var that = this
      return this.data[this.openIndex]
    }
  }
}
</script>
