import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleButton"];

  connect() {
    this.isDisplay = false;
    if (localStorage.getItem('huduAssetSidebarIsSplit')) {
      try {
        this.isDisplay = JSON.parse(localStorage.getItem('huduAssetSidebarIsSplit'));
        this.updateUI();
      } catch(e) {
        console.error(e);
      }
    }
  }

  toggle(event) {
    event.preventDefault();
    this.isDisplay = !this.isDisplay;
    localStorage.setItem('huduAssetSidebarIsSplit', this.isDisplay);
    this.updateUI();
  }

  updateUI() {
    var columns = document.querySelector(".cpanel");
    var aside = document.querySelector(".cpanel__aside");
    if (this.isDisplay) {
      columns.classList.add("cpanel--full");
      aside.classList.add("cpanel__aside--blank");
      this.toggleButtonTarget.classList.add("cpanel__switch--open");
    } else {
      columns.classList.remove("cpanel--full");
      aside.classList.remove("cpanel__aside--blank");
      this.toggleButtonTarget.classList.remove("cpanel__switch--open");
    }
  }
}
