<template>
  <div class='form-group-half'>
    <div class='form-group'>
      <label>Country</label>
      <country-select v-model="country" :country="country" topCountry="US" />
      <input type='hidden' :name='country_name' v-model='country'>
    </div>

    <div class='form-group'>
      <label>State/Region/Province</label>
      <region-select v-model="region" :country="country" :region="region" />
      <input type='hidden' :name='region_name' v-model='region'>
    </div>
  </div>
</template>

<script>
import vueCountryRegionSelect from 'vue-country-region-select'

export default {
  props: ["country_name", "country_value", "region_name", "region_value"],
  components: {
    'country-select': vueCountryRegionSelect.CountrySelect,
    'region-select': vueCountryRegionSelect.RegionSelect
  },
  data: function () {
    return {
      country: this.country_value,
      region: this.region_value
    }
  }
}
</script>
