import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import App from "../components/EntityActivityLog.vue";

export default class extends Controller {
  connect() {
    const el = this.element;
    this.vueInstance = new Vue({
      el,
      render: h => h(App, {
        props: {
          activity_logs_url: el.dataset.activityLogsUrl,
          url: el.dataset.url,
          admin: el.dataset.admin === "true"
        }
      })
    });
  }
}
