import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'
export default class extends Controller {
  static targets = ["listNameInput", "nameInput", "changeLink"]

  connect() {

  }

  change (e) {
    e.preventDefault();

    this.nameInputTarget.style.display = "block";
    this.changeLinkTarget.style.display = "none";
    this.listNameInputTarget.remove()
    this.listNameInputTarget.remove()
  }
}
