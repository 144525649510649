import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["search"]

  search(event) {
    var folders = document.querySelector(".index__folders");
    if (folders) {
      if (this.searchTarget.value ) {
        folders.style.display = "none";
      } else {
        folders.style.display = "block";
      }
    }
  }
}
