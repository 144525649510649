import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form"]

  connect() {
    this.toggleForm(); // Call on connect to set initial state based on preselected options if any
  }

  toggleForm() {
    const specificInput = document.querySelector("#password_folder_security_specific");
    this.formTarget.style.display = specificInput.checked ? "block" : "none";
  }
}
