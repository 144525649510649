import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import axios from "axios";
import App from "../components/Alerter.vue";

export default class extends Controller {
  connect() {

    const el = this.element;
    Vue.use({
          install (Vue) {
          Vue.prototype.$api = axios.create({
            headers: {
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
          })
        }
      })
    this.vueInstance = new Vue({
      el,
      render: h => h(App, {
        props: {
          prealert_type: el.dataset.prealertType,
          preemail: el.dataset.preemail,
          prewebhook_url: el.dataset.prewebhookUrl,
          structure_name: el.dataset.structureName,
          prewebhook_payload: el.dataset.prewebhookPayload,
          prestop_on_trigger: el.dataset.prestopOnTrigger,
          preinclude_archived_records: el.dataset.preincludeArchivedRecords,
          preexpiration_type: el.dataset.preexpirationType,
          prerecord_event_type: el.dataset.prerecordEventType,
          prerecord_type: el.dataset.prerecordType,
          predays_until: el.dataset.predaysUntil
        }
      })
    });
  }
}
