
const state = {
  sub_open: 0,
  search_query: ""
}

const getters = {
  showRinger (state) {
    return state.sub_open == 1 ? true : false
  },
  showSearcher (state) {
    return state.sub_open == 2 ? true : false
  },
  showDropper (state) {
    if (state.sub_open == 3 || state.sub_open == 8 || state.sub_open == 9 || state.sub_open == 10) {
      return true;
    } else {
      return false
    }
  },
  currentSubOpen (state) {
    return state.sub_open;
  },
  showExpiring (state) {
    return state.sub_open == 10 ? true : false
  },
  showFavorites (state) {
    return state.sub_open == 9 ? true : false
  },
  showRecents (state) {
    return state.sub_open == 8 ? true : false
  },
  showProfile (state) {
    return state.sub_open == 3 ? true : false
  },
  showVault (state) {
    return state.sub_open == 4 ? true : false
  },
  getSearchQuery (state) {
    return state.search_query
  },
  showSyncBar (state) {
    return state.sub_open == 5 ? true : false
  },
  showSubMenu (state) {
    return state.sub_open == 6 ? true : false
  },
  showOnboarder (state) {
    return state.sub_open == 7 ? true : false
  },
}

const actions = {}

const mutations = {
  changeSub (state, newSub) {
    state.sub_open = newSub;
  },
  changeSearchQuery (state, newState) {
    state.search_query = newState;
    state.sub_open = 2
  },
  changeHeader (state, newHeader) {
    state.header_open = newHeader;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
