import { Controller } from '@hotwired/stimulus';
import { useTransition } from 'stimulus-use';

export default class extends Controller {
  static targets = ['menu'];

  connect() {
    useTransition(this, {
      element: this.menuTarget,
      enterActive: 'enter-active',
    });
  }

  toggle({ target: button }) {
    this.toggleTransition();
    this.updateMenuPosition(button);
  }

  updateMenuPosition(button) {
    const menuRect = this.menuTarget.getBoundingClientRect();
    const buttonRect = button.getBoundingClientRect();

    if (!this.menuTarget.classList.contains('enter-active')) return;

    if (buttonRect.right <= menuRect.width) {
      this.hangLeft();
      return;
    };

    this.hang(menuRect, button.offsetHeight);
  }

  hang(menuRect, buttonOffsetHeight) {
    if (menuRect.left < 0) {
      this.hangLeft();
    }
    else if (menuRect.right > window.innerWidth) {
      this.hangRight();
    }

    this.hangTop(buttonOffsetHeight);
    // if (menuRect.top < 0) {
    //   this.hangTop(buttonOffsetHeight);
    // }
    // else if (menuRect.bottom > window.innerHeight) {
    //   this.hangBottom(buttonOffsetHeight);
    // }
  }

  hangLeft() {
    this.menuTarget.style.left = '0px';
    this.menuTarget.style.right = 'auto';
  }

  hangRight() {
    this.menuTarget.style.right = '0px';
    this.menuTarget.style.left = 'auto';
  }

  hangBottom(buttonHeight) {
    this.menuTarget.style.bottom = `${buttonHeight + 10}px`;
  }

  hangTop(buttonHeight) {
    this.menuTarget.style.top = `${buttonHeight + 10}px`;
  }

  hide(event) {
    const node = event.target;
    if (!this.element.contains(node) && !this.menuTarget.classList.contains('hidden')) {
      this.leave();
    }
  }
}
