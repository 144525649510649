<template>
  <div style='position: relative;' class='otp-input'>

    <input type='hidden' :value='value' :name='name'>
    <input type='hidden' :value='isOtpEdit' name='otp_changed'>
    <div class='otp-inputer'>
      <section>
        <h1>Enter secret key</h1>
        <p>Must use Base32 formatting</p>
        <template v-if='!has_prevalue || isOtpEdit'>
          <div class='inputer'>
            <input :id='id' type='text' placeholder='Enter text-based OTP secret key' :value="displayValue" @keydown.space.prevent @input="format($event)" @focus="startEditing">
            <aside>
              <a href='#' @click.prevent.stop="clear"><i class='fas fa-trash icon'></i>Clear</a>
            </aside>
          </div>
        </template>
        <template v-if='has_prevalue && !isOtpEdit'>
          <div class='button-group'>
            <a href='#' @click.prevent.stop='isOtpEdit = true' class='button button--secondary'>Change OTP</a>
          </div>
        </template>
      </section>
      <template v-if='!has_prevalue || isOtpEdit'>
        <div v-if='!isShown'>
          <a href='#' @click.prevent.stop='toggle'>Scan a QR code to fill the secret...</a>
        </div>
        <div v-if='isShown'>
          <h1>Upload QR code</h1>
          <input type='file' accept="image/x-png" id='qrInput' />
          <div class='button-group'>
            <a href='#' class='button button--small button--secondary' @click.prevent.stop='scan'>Scan and fill secret key</a>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import {Html5Qrcode} from "html5-qrcode";

export default {
  props: ["name", "has_prevalue", "prevalue", "id", "show_otp_secrets_when_editing"],
  methods: {
    choose (id, name) {
      this.chosen = {id: id, name}
      this.isShown = false;
    },
    format (e) {
      if (!this.isActivelyEditing && !this.show_otp_secrets_when_editing && this.has_prevalue) {
        this.value = '';
      }
      this.value = e.target.value.toUpperCase().replace(/\s/g, '');
      this.isActivelyEditing = true;
    },
    startEditing() {
      if (!this.show_otp_secrets_when_editing && this.has_prevalue) {
        this.value = '';
      }
      this.isActivelyEditing = true;
    },
    clear () {
      this.value = ""
    },
    toggle () {

      this.isShown = true

      this.$nextTick(function () {
        var that = this;

        const html5QrCode = new Html5Qrcode("qrInput");
        const fileinput = document.getElementById('qrInput');
        fileinput.addEventListener('change', e => {
            if (e.target.files.length == 0) {
              return;
            }

            var image = fileinput.files[0];
            // Scan QR Code
            html5QrCode.scanFile(image, true)
            .then(decodedText => {
              this.qrData = decodedText;
            })
            .catch(err => {
              console.log(`Error scanning file. Reason: ${err}`)
            });
          });
      });
    },
    scan () {
      var regex = /[?&]([^=#]+)=([^&#]*)/g,
        url = this.qrData,
        params = {},
        match;
      while(match = regex.exec(url)) {
        params[match[1]] = match[2];
      }
      if (params.secret) {
        this.value = params.secret
      } else {
        alert("Could not extract OTP secret!")
      }
    }
  },
  computed: {
    displayValue() {
      if (this.isActivelyEditing) {
        return this.value;
      }
      return (this.show_otp_secrets_when_editing || !this.has_prevalue) ? this.value : '';
    }
  },
  mounted () {
    if (this.has_prevalue) {
      this.value = this.prevalue;
      if (this.show_otp_secrets_when_editing) {
        this.isOtpEdit = true;
      }
    }
    this.isActivelyEditing = false;
  },
  data () {
    return {
      file: "",
      isShown: false,
      isOtpEdit: false,
      qrData: "",
      value: "",
      isActivelyEditing: false
    }
  }
}
</script>

