import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    index: Number,
    url: String,
    pre: Number
  };

  connect() {
    this.indexValue = this.preValue; // Initialize selected index with `pre` value
    this.highlightSelected()
  }

  changeSelected(event) {
    const newIndex = event.currentTarget.getAttribute('data-index-value');
    this.indexValue = parseInt(newIndex, 10); // Update the selected index

    // Implement your API call here
    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ dashboard_image: this.indexValue }),
    })
    .then(response => response.json())
    .then(data => {
      this.highlightSelected();
    })
    .catch((error) => {
      console.error('Error:', error);
      alert("Something went wrong...");
    });
  }

  highlightSelected() {
    this.element.querySelectorAll('.dashboard__item-picture').forEach((element, index) => {
      if (index === this.indexValue) {
        element.classList.add('dashboard__item-picture--selected');
      } else {
        element.classList.remove('dashboard__item-picture--selected');
      }
    });
  }
}
