import { Controller } from '@hotwired/stimulus'
import SwaggerUI from 'swagger-ui'

export default class extends Controller {
  static values = { url: {type: String}};

  connect() {
    SwaggerUI({
      dom_id: '#swagger',
      url: this.urlValue,
    })
  }
}
