import { Controller } from "@hotwired/stimulus";
import initTiny from "../lib/tinymce-editor.js";

export default class extends Controller {
  static targets = [ "input" ]
  connect() {
    tinymce.remove("#" + this.inputTarget.id);
    initTiny(this, "#" + this.inputTarget.id, null, true)
  }
  disconnect() {
    tinymce.remove("#" + this.inputTarget.id);
  }
}
