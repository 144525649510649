import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-modal"
export default class extends Controller {
  static targets = ["icon"]
  toggle(event) {
    event.preventDefault()
    if (!this.element.getElementsByTagName('ul')) {
      return;
    }

    if (this.element.getElementsByTagName('ul')[0]) {
      if (this.element.getElementsByTagName('ul')[0].style.display == 'block') {
        this.iconTarget.classList.remove('fa-chevron-down')
        this.iconTarget.classList.add('fa-chevron-right')
        this.element.getElementsByTagName('ul')[0].style.display = 'none'
      } else {
        this.iconTarget.classList.remove('fa-chevron-right')
        this.iconTarget.classList.add('fa-chevron-down')
        this.element.getElementsByTagName('ul')[0].style.display = 'block'
      }
    } else {
      if (this.iconTarget.classList.contains('fa-chevron-down')) {
        this.iconTarget.classList.remove('fa-chevron-down')
        this.iconTarget.classList.add('fa-chevron-right')
      } else {
        this.iconTarget.classList.remove('fa-chevron-right')
        this.iconTarget.classList.add('fa-chevron-down')
      }
    }
  }
}
