import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "hideable" ]
  static values = { display: { type: String, default: "block" } }

  toggleTargets() {
    this.hideableTargets.forEach((el) => {
      if (el.style.display == "none") {
        el.style.display = this.displayValue;
        if (document.querySelector('.sidebar')) {
          document.querySelector('.sidebar').style.display = "block";
        }
      } else {
        el.style.display = "none";
        if (document.querySelector('.sidebar')) {
          document.querySelector('.sidebar').style.display = "none";
        }
      }
    });
  }
}
