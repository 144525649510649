import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import axios from "axios";
import App from "../components/FileManager.vue";

export default class extends Controller {
  connect() {
    const el = this.element;
    Vue.use({
          install (Vue) {
          Vue.prototype.$api = axios.create({
            headers: {
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
          })
        }
      })
    this.vueInstance = new Vue({
      el,
      render: h => h(App, {
        props: {
          name: el.dataset.name,
          url: el.dataset.url,
          uploadable_id: el.dataset.uploadableId,
          uploadable_type: el.dataset.uploadableType,
          can_create: el.dataset.canCreate === "true",
          can_archive: el.dataset.canArchive === "true",
          base_url: el.dataset.baseUrl,
          max_file_size: el.dataset.maxFileSize
        }
      })
    });
  }
}
