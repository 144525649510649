import { Controller } from "@hotwired/stimulus"
import CryptoJS from "crypto-js"

export default class extends Controller {
  static targets = ["revealButton", "noteContent"]
  static values = {
    encryptedMessage: String,
    key: String,
    revealUrl: String
  }

  reveal(event) {
    event.preventDefault()
    fetch(this.revealUrlValue, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json'
      },
      credentials: 'same-origin'
    })
    .then(data => {
      const decryptedMessage = CryptoJS.AES.decrypt(this.encryptedMessageValue, this.keyValue)
      const note = decryptedMessage.toString(CryptoJS.enc.Utf8)

      this.noteContentTarget.textContent = note
      this.noteContentTarget.style.display = 'block'
      this.revealButtonTarget.style.display = 'none'
    })
    .catch(error => console.error('Error:', error))
  }
}
