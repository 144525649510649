import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "circle", "input" ]
  static values = {
    updateUrl: String,
    default: String
  }

  select(event) {
    event.preventDefault()
    const color = event.currentTarget.getAttribute('data-color-picker-color')
    this.updateSelection(color)
    this.inputTarget.value = color
  }

  updateSelection(selectedColor) {
    this.circleTargets.forEach((el) => {
      const color = el.getAttribute('data-color-picker-color')
      if (selectedColor === color) {
        el.querySelector('.circle').classList.add("selected")
      } else {
        el.querySelector('.circle').classList.remove("selected")
      }
    })
  }
}
