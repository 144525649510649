import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import store from "../store"
import axios from "axios";
import App from "../components/ProcessEditor.vue";

export default class extends Controller {
  connect() {
    const el = this.element;

    Vue.use({
          install (Vue) {
          Vue.prototype.$api = axios.create({
            headers: {
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
          })
        }
      })
    this.vueInstance = new Vue({
      el,
      store,
      render: h => h(App, {
        props: {
          users: JSON.parse(el.dataset.users),
          structure_name: el.dataset.structureName,
          dark_mode: el.dataset.darkMode === "true",
          asset_name: el.dataset.assetName,
          turn_off_completion: el.dataset.turnOffCompletion === "true",
          url: el.dataset.url,
          can: el.dataset.can === "true"
        }
      })
    });
  }
}
