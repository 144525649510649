<template>
  <div class='asset-sidebar__group asset-sidebar__group--photo-manager' @keydown.esc="close">
    <header>
      <h2>
        <widget-toggler name="Activity">Activity Feed</widget-toggler>
      </h2>
    </header>
    <div class="asset-sidebar__group-content asset-sidebar__group-content--Activity">
      <div class="tabs">
        <a href="#" :class='{"selected": action == "update"}' @click.prevent.stop='changeAction("update")'>Updates</a>
        <a href="#" :class='{"selected": action == "view"}' @click.prevent.stop='changeAction("view")'>Views</a>
      </div>
      <div class='asset-sidebar__box asset-sidebar__box--feed'>
        <section v-for='recording in recordings'>
          <h3 class='asset-sidebar__box--feed--full'>
            <div class='asset-sidebar__initials' v-if='recording.profile_image_url == null'>
              <svg viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="34.5" cy="34.5" r="34.5" fill="#6136FF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M31.1588 29.4722L31.1612 29.4746C32.2151 30.2291 33.471 30.6526 34.7671 30.6916C41.3667 30.8912 43.4526 31.2307 43.4526 31.2307C43.9066 31.2806 44.3568 31.3366 44.801 31.4011C45.1442 31.451 45.401 31.0895 45.2379 30.7804C43.7958 28.069 41.0467 24.5884 37.3471 21.658C30.9141 16.5637 24.0468 15.0583 22.8699 16.5455C21.6919 18.0338 24.7295 24.3755 31.1588 29.4709V29.4722ZM59.0579 40.9933V40.9872L53.27 36.2495C50.8141 34.2378 47.2983 33.3689 43.4782 32.9539C43.4782 32.9539 39.9076 32.3734 27.2863 32.2907C16.6561 32.2201 10.2645 33.1207 10.2645 33.1207C7.19286 33.4529 7.82812 34.9425 8.80292 36.1497C9.93592 37.5517 11.2563 38.7443 12.7106 39.7094C14.9985 41.2281 17.5883 42.136 20.2498 42.4269C21.3195 42.5425 22.3941 42.5522 23.4541 42.4597C25.9343 42.2431 28.6287 41.2841 30.7608 40.0172C31.0249 39.8615 31.1807 39.5731 31.1807 39.2664V39.2092C31.1807 38.7857 30.874 38.5983 30.4967 38.8003L30.4894 38.8039C29.2372 39.4696 27.5103 40.0233 26.0535 40.1499C25.7749 40.1742 25.4925 40.1864 25.2138 40.1901H25.2029C24.9254 40.1888 24.6431 40.1718 24.3644 40.1499C22.9077 40.0221 21.4875 39.6254 20.2376 38.9585C19.4417 38.5362 18.7176 38.0117 18.0982 37.3983C18.0957 37.3947 18.0909 37.391 18.0884 37.3874C17.7282 37.0198 17.9254 36.3943 18.428 36.2811C19.5561 36.028 23.7839 35.2589 30.571 36.1351V36.1375C33.7655 36.5501 36.2299 38.4997 36.2299 41.8086H36.2311V45.5533C36.2311 49.5303 39.4561 52.7541 43.4319 52.7541H50.8628C53.1252 52.7541 55.2244 51.5773 56.4025 49.6423L59.6287 44.3533C60.3041 43.2483 60.0583 41.8123 59.0567 40.9933H59.0579ZM51.5942 41.1113C51.5212 41.1661 50.0158 42.276 48.5116 41.9109C48.1769 41.8391 47.8751 41.6845 47.6062 41.5044C46.6801 40.8813 46.1677 39.8554 46.1446 39.7982C46.057 39.6278 46.1044 39.4234 46.2578 39.3114H46.259C46.3247 39.2639 47.8301 38.1541 49.3416 38.5106C50.8458 38.8757 51.6648 40.5515 51.7049 40.6282C51.7926 40.7985 51.7305 41.0006 51.5942 41.1101V41.1113Z" fill="white"/>
              </svg>
            </div>
            <div class='asset-sidebar__initials' v-if='recording.profile_image_url != null'>
              <img :src='recording.profile_image_url'>
            </div>
            <article>
              <a v-if='recording.url' :href='recording.url'>{{recording.user_short_name || "HuduBot"}} {{recording.action}}</a>
              <p v-if='!recording.url'>{{recording.user_short_name|| "HuduBot" }} {{recording.action}}</p>
              <div class='asset-sidebar__box__description'>{{recording.formatted_datetime}}</div>
            </article>
          </h3>
        </section>
        <p class='asset-sidebar__more' v-if='action == "update" && meta.additional_updates > 0'>+{{meta.additional_updates}} more updates...</p>
        <section v-show='action == "update"'>
          <h3 class='asset-sidebar__box--feed--full'>
            <div class='asset-sidebar__initials' v-if='meta.created.profile_image_url == null'>
              <svg viewBox="0 0 69 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="34.5" cy="34.5" r="34.5" fill="#6136FF"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M31.1588 29.4722L31.1612 29.4746C32.2151 30.2291 33.471 30.6526 34.7671 30.6916C41.3667 30.8912 43.4526 31.2307 43.4526 31.2307C43.9066 31.2806 44.3568 31.3366 44.801 31.4011C45.1442 31.451 45.401 31.0895 45.2379 30.7804C43.7958 28.069 41.0467 24.5884 37.3471 21.658C30.9141 16.5637 24.0468 15.0583 22.8699 16.5455C21.6919 18.0338 24.7295 24.3755 31.1588 29.4709V29.4722ZM59.0579 40.9933V40.9872L53.27 36.2495C50.8141 34.2378 47.2983 33.3689 43.4782 32.9539C43.4782 32.9539 39.9076 32.3734 27.2863 32.2907C16.6561 32.2201 10.2645 33.1207 10.2645 33.1207C7.19286 33.4529 7.82812 34.9425 8.80292 36.1497C9.93592 37.5517 11.2563 38.7443 12.7106 39.7094C14.9985 41.2281 17.5883 42.136 20.2498 42.4269C21.3195 42.5425 22.3941 42.5522 23.4541 42.4597C25.9343 42.2431 28.6287 41.2841 30.7608 40.0172C31.0249 39.8615 31.1807 39.5731 31.1807 39.2664V39.2092C31.1807 38.7857 30.874 38.5983 30.4967 38.8003L30.4894 38.8039C29.2372 39.4696 27.5103 40.0233 26.0535 40.1499C25.7749 40.1742 25.4925 40.1864 25.2138 40.1901H25.2029C24.9254 40.1888 24.6431 40.1718 24.3644 40.1499C22.9077 40.0221 21.4875 39.6254 20.2376 38.9585C19.4417 38.5362 18.7176 38.0117 18.0982 37.3983C18.0957 37.3947 18.0909 37.391 18.0884 37.3874C17.7282 37.0198 17.9254 36.3943 18.428 36.2811C19.5561 36.028 23.7839 35.2589 30.571 36.1351V36.1375C33.7655 36.5501 36.2299 38.4997 36.2299 41.8086H36.2311V45.5533C36.2311 49.5303 39.4561 52.7541 43.4319 52.7541H50.8628C53.1252 52.7541 55.2244 51.5773 56.4025 49.6423L59.6287 44.3533C60.3041 43.2483 60.0583 41.8123 59.0567 40.9933H59.0579ZM51.5942 41.1113C51.5212 41.1661 50.0158 42.276 48.5116 41.9109C48.1769 41.8391 47.8751 41.6845 47.6062 41.5044C46.6801 40.8813 46.1677 39.8554 46.1446 39.7982C46.057 39.6278 46.1044 39.4234 46.2578 39.3114H46.259C46.3247 39.2639 47.8301 38.1541 49.3416 38.5106C50.8458 38.8757 51.6648 40.5515 51.7049 40.6282C51.7926 40.7985 51.7305 41.0006 51.5942 41.1101V41.1113Z" fill="white"/>
              </svg>
            </div>
            <div class='asset-sidebar__initials' v-if='meta.created.profile_image_url != null'>
              <img :src='meta.created.profile_image_url'>
            </div>
            <article>
              <a v-if='meta.created.url' :href='meta.created.url'>{{meta.created.user_short_name || "HuduBot"}} created</a>
              <p v-if='!meta.created.url'>{{meta.created.user_short_name  || "HuduBot"}} created</p>
              <div class='asset-sidebar__box__description' v-if='meta.created.formatted_datetime'>{{meta.created.formatted_datetime}}</div>
            </article>
          </h3>
        </section>
      </div>
      <a :href='activity_logs_url' v-if='admin' class='asset-sidebar__group__footer'>View all activity</a>
    </div>
  </div>
</template>

<script>
import flash from "../lib/flashes.js";
import WidgetToggler from './WidgetToggler.vue';
export default {
  props: ["url", "activity_logs_url", "admin"],
  data () {
    return {
      recordings: [],
      meta: {
        created: {
          user_initials: "HB",
          url: null,
          profile_image_url: "",
          user_short_name: "HuduBot"
        }
      },
      action: "update"
    }
  },
  mounted () {
    this.getRecordings()
  },
  components: {
    WidgetToggler,
  },
  methods: {
    changeAction (action) {
      this.action = action
      this.getRecordings()
    },
    getRecordings () {
      var that = this;
      that.$api.get(that.url + "&actionType=" + that.action)
      .then(function(response) {
        that.recordings = response.data.recordings
        that.meta = response.data.meta
      }).catch(error => {
        flash("Something went wrong!", "error")
      })
    }
  },

}
</script>

