import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["toggleable"]
  static values = {
    show: { type: String, default: "true" }
  }

  connect() {
    this.toggle()
  }

  toggle() {
    const selectedValue = this.element.querySelector('input[type="radio"]:checked').value
    this.toggleableTargets.forEach(target => {
      target.style.display = selectedValue === this.showValue ? "block" : "none"
    })
  }
}
