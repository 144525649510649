import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["content"]
  static values = { selected: String }

  connect() {
    this.updateVisibility();
  }

  updateVisibility() {
    this.contentTargets.forEach((content) => {
      if (content.dataset.value === this.selectedValue) {
        content.style.display = "block";
      } else {
        content.style.display = "none";
      }
    });
  }

  selectOption(event) {
    this.selectedValue = event.target.value;
    this.updateVisibility();
  }
}
