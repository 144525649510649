import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import store from "../store"
import App from "../components/OtpInputer.vue";

export default class extends Controller {
  connect() {
    const el = this.element;

    this.vueInstance = new Vue({
      el,
      store,
      render: h => h(App, {
        props: {
          prevalue: el.dataset.prevalue,
          has_prevalue: el.dataset.hasPrevalue === "true",
          show_otp_secrets_when_editing: el.dataset.showOtpSecretsWhenEditing === "true",
          name: el.dataset.name,
          id: el.dataset.id
        }
      })
    });
  }
}
