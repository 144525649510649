import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="turbo-modal"
export default class extends Controller {
  static targets = ["content"]

  connect() {
    document.addEventListener('keydown', this.handleKeydown.bind(this))
    this.element.addEventListener('click', this.handleOutsideClick.bind(this))
  }

  disconnect() {
    document.removeEventListener('keydown', this.handleKeydown.bind(this))
    this.element.removeEventListener('click', this.handleOutsideClick.bind(this))
  }

  handleOutsideClick(event) {
    if (!this.contentTarget.contains(event.target) && event.target === this.element) {
      this.hideModal()
    }
  }

  handleKeydown(event) {
    if (event.key === "Escape") {
      this.hideModal()
    }
  }

  hideModal() {
    this.element.parentElement.removeAttribute("src") // it might be nice to also remove the modal SRC
    this.element.remove()
  }
}
