import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import App from "../components/LayoutSorter.vue";

export default class extends Controller {
  connect() {
    const el = this.element;
    this.vueInstance = new Vue({
      el,
      render: h => h(App, {
        props: {
          prerules: JSON.parse(el.dataset.prerules),
          role_types: JSON.parse(el.dataset.roleTypes),
          asset_layouts: JSON.parse(el.dataset.assetLayouts)
        }
      })
    });
  }
}
