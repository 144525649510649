<template>
  <div>
    <a href='#' @click.prevent.stop='toggleEdit' :disabled='showEdit' class='button button--secondary'>Edit</a>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  props: ["url", "can", "turn_off_completion"],
  computed: {
    ...mapState("procedures", {
      'showEdit':  'showEdit',
      'procedure': 'procedure',
      'showDescription': 'showDescription',
      'openProcessView': 'openProcessView',
      'openProcessEdit': 'openProcessEdit',
      'openCompletionEdit': 'openCompletionEdit',
    }),
     ...mapGetters("procedures", {
      'tasksLength':  'tasksLength',
      'procedureJSON': 'procedureJSON'
    }),
    procedure_tasks_attributes: {
      get() {
        return this.procedure.procedure_tasks_attributes
      },
      set(value) {
        this.$store.dispatch('procedures/update_tasks', value)
      }
    }
  },
  mounted () {
    //this.$store.dispatch('procedures/setState', [this.url, this.can, this.template])
    //this.$store.dispatch('procedures/fetch');

  },
  methods: {
    submitForm () {
      this.$store.dispatch('procedures/submitForm');
    },
    toggleEdit () {
      this.$store.dispatch('procedures/toggleEdit');
    },
  }
}
</script>

