import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["idInput", "numberSpan"]

  // This component works with checkbox-select-all to move items to a new location
  // The hidden input is used to store the id of the new location
  // We use a trick in the connect of the checkbox-select-all to allow us to access the checked values
  // The tricky part is that the move modal is not a child of the table,
  //so we can't use the checked values directly
  connect() {
    var checkboxSelectAll = document.querySelector('#datatable')["checkbox-select-all"] // Uses the trick to access the checkbox-select-all controller

    var checked_values = checkboxSelectAll.checked.map(function (el) {
      return el.value
    })
    this.idInputTarget.value = JSON.stringify(checked_values)
    this.numberSpanTarget.innerHTML = checked_values.length
  }
}
