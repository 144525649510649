import { Controller } from "@hotwired/stimulus";
var dayjs = require('dayjs')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')

export default class extends Controller {
  static targets = [ "time", "date" ]
  connect() {
    var that = this;
    this.user_timezone = this.element.dataset.timezone
    this.military_time = this.element.dataset.militaryTime === "true"
    that.getDateAndTime()
    this.interval = setInterval(function(){
      that.getDateAndTime()
    }, 1000);
  }
  disconnect() {
    clearInterval(this.interval)
  }
  getDateAndTime () {
    dayjs.extend(utc)
    dayjs.extend(timezone)
    var now = dayjs().tz(this.user_timezone)
    if (this.military_time) {
      this.time = now.format('H:mm')
    } else {
      this.time = now.format('h:mm')
      this.time_span = now.format('A')
    }

    this.date = now.format('MMMM D, YYYY')

    if (this.time_span) {
      this.timeTarget.innerHTML = this.time + "<span> " + this.time_span + "</span>"
    } else {
      this.timeTarget.innerHTML = this.time
    }

    this.dateTarget.innerHTML = this.date
  }
}
