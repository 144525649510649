<template>
  <div style='position: relative;' class='form-group folder__chooser'>
    <input type='hidden' id='folderIdInput' :value='chosen.id' :name="name">
    <label>Folder</label>
    <section>
      <a href='#' class='button button--secondary' @click.prevent.stop="isShown = !isShown">Choose Folder</a>
      <p>{{chosen.name || "No folder"}}</p>
    </section>
    <div class='folder__options' v-if='isShown'>
      <div class='folder__option'>
        <a href='#' class='button button--small button--secondary' @click.prevent.stop='choose("", "")'>Choose</a>
        No folder
      </div>
      <div class='folder__option' v-for='folder in folders'>
        <a href='#' class='button button--small button--secondary' @click.prevent.stop='choose(folder.id, folder.name)'>Choose</a>
        <i class='fas fa-folder'></i>{{folder.name}}
        <div class='folder__child' v-for='child in folder.children'>
          <a href='#' class='button button--small button--secondary' @click.prevent.stop='choose(child.id, child.name)'>Choose</a>
          <i class='fas fa-folder'></i>{{child.name}}
          <div class='folder__child' v-for='child2 in child.children'>
            <a href='#' class='button button--small button--secondary' @click.prevent.stop='choose(child2.id, child2.name)'>Choose</a>
            <i class='fas fa-folder'></i>{{child2.name}}
            <div class='folder__child' v-for='child3 in child2.children'>
              <a href='#' class='button button--small button--secondary' @click.prevent.stop='choose(child3.id, child3.name)'>Choose</a>
              <i class='fas fa-folder'></i>{{child3.name}}
                <div class='folder__child' v-for='child4 in child3.children'>
                <a href='#' class='button button--small button--secondary' @click.prevent.stop='choose(child4.id, child4.name)'>Choose</a>
                <i class='fas fa-folder'></i>{{child4.name}}
                <div class='folder__child' v-for='child5 in child4.children'>
                  <a href='#' class='button button--small button--secondary' @click.prevent.stop='choose(child5.id, child5.name)'>Choose</a>
                  <i class='fas fa-folder'></i>{{child5.name}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["name", "value", "folders", "existing_name", "changeFolder"],
  methods: {
    choose (id, name) {
      this.chosen = {id: id, name}
      this.isShown = false;
      this.$emit("changeFolder", id)
    }
  },
  data () {
    return {
      isShown: false,
      chosen: {
        id: this.value,
        name: this.existing_name
      }
    }
  }
}
</script>

