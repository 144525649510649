import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import App from "../components/Tagger.vue";
import axios from "axios";

export default class extends Controller {
  connect() {
    const el = this.element;

    Vue.use({
      install (Vue) {
        Vue.prototype.$api = axios.create({
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
        })
      }
    })

    this.vueInstance = new Vue({
      el,
      render: h => h(App, {
        props: {
          name: el.dataset.name,
          company: el.dataset.company,
          link_name: el.dataset.linkName,
          linkable_id: el.dataset.linkableId,
          asset_layout_name: el.dataset.assetLayoutName,
          existing: JSON.parse(el.dataset.existing),
          options: JSON.parse(el.dataset.options)
        }
      })
    });
  }
}
