import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["userEmails", "emailInput", "emptyMessage", "invitesList", "submitButton"]

  connect() {
    this.users = []
    this.updateUserEmails()
    this.updateSubmitButton()
  }

  add(event) {
    event.preventDefault()
    const emails = this.emailInputTarget.value.split(",")

    emails.forEach(email => {
      email = email.toLowerCase().trim()
      if (this.validateEmail(email) && !this.users.includes(email)) {
        this.users.push(email)
      }
    })

    this.emailInputTarget.value = ""
    this.updateUserEmails()
    this.renderInvites()
    this.updateSubmitButton()
  }

  handleKeydown(event) {
    if (event.key === "Enter") {
      event.preventDefault()
      this.add(event)
    }
  }

  remove(event) {
    event.preventDefault()
    const index = event.params.index
    this.users.splice(index, 1)
    this.updateUserEmails()
    this.renderInvites()
    this.updateSubmitButton()
  }

  validateEmail(email) {
    const re = /\S+@\S+\.\S+/
    return re.test(email)
  }

  updateUserEmails() {
    this.userEmailsTarget.value = JSON.stringify(this.users)
  }

  renderInvites() {
    this.emptyMessageTarget.style.display = this.users.length === 0 ? "block" : "none"
    this.invitesListTarget.innerHTML = this.users.map((email, index) => `
      <div class="bulk_user_invites__invite">
        <p>${email}</p>
        <a href="#" class="button button--plain button--icon" data-action="click->bulk-user-inviter#remove" data-bulk-user-inviter-index-param="${index}">
          <i class="far fa-trash-can"></i>
        </a>
      </div>
    `).join('')
  }

  updateSubmitButton() {
    if (this.submitButtonTarget) {
      this.submitButtonTarget.disabled = this.users.length === 0
    }
  }
}
