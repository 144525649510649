import { Controller } from "@hotwired/stimulus"
import flash from "../lib/flashes.js"
import axios from "axios"

export default class extends Controller {
  static targets = [ "result" ]
  static values = {
    url: String,
    isIntegration: {
      type: Boolean,
      default: false
    }
  }

  test() {

    const that = this
    // Custom headers
    const headers = {
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    };

    axios.post(that.urlValue, null, {headers: headers}).then(function (response) {
      flash("Connection test successful","success")
    }).catch(error => {
      if (error.response && error.response.data && error.response.data.message) {
        flash(error.response.data.message,"error")
      } else {
        flash("Unable to connect","error")
      }
    })

  }


}
