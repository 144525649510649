import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import axios from "axios";
import App from "../components/PdfUploader.vue";

export default class extends Controller {
  connect() {
    const el = this.element;

    Vue.use({
          install (Vue) {
          Vue.prototype.$api = axios.create({
            headers: {
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
          })
        }
      })
    this.vueInstance = new Vue({
      el,
      render: h => h(App, {
        props: {
          upload_path: el.dataset.uploadPath,
        }
      })
    });
  }
}
