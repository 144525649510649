
<template>
  <div class='list-picker'>
    <template v-if='!isAdd'>
      <input type='hidden' :name="name" v-model='value'>
        <multiselect :options="all_options"
                    label="name" @input='changeSelected' track-by="id" :multiple='multiple_options' :closeOnSelect='!multiple_options' v-model='selected'></multiselect>
        <div class='button-group' style="margin-top: .25rem;">
          <a href='#' @click.prevent.stop='changeIsAdd' class='button button--success'><i class='fas fa-plus'></i>Add Item</a>
        </div>
    </template>
    <template v-if='isAdd'>
      <div class='input-group'>
        <input v-on:keyup.enter="addItem($event)" type='text' v-model='newListItem' placeholder='Enter a new item...' />
        <a href='#' class='button button--success' @click.prevent.stop='addItem'>
          <i class='fas fa-plus'></i> Add To List
        </a>
      </div>
    </template>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import flash from "../lib/flashes.js";
export default {
  props: ["options", "required", "name", "prevalue", "multiple_options", "new_list_item_url"],
  components: {
    Multiselect
  },
  mounted () {
    var that = this;
    if (this.prevalue) {
      this.selected = this.prevalue;
      var returnArray = [];
      this.prevalue.forEach((element) => {
        returnArray.push(element.id)
      });
      that.value = JSON.stringify({"list_ids": returnArray});
    }
  },
  methods: {
    changeSelected (newValue, newId) {
      this.setValues();
    },
    setValues () {
      var returnArray = [];
      if (this.multiple_options) {
        if (this.selected && this.selected.length > 0) {
          this.selected.forEach((element) => {
            returnArray.push(element.id);
          });
        }
      } else {
        if (this.selected) {
          returnArray.push(this.selected.id);
        }
      }
      this.value = JSON.stringify({"list_ids": returnArray});
    },
    changeIsAdd () {
      this.isAdd = true;
      function checkEnter(e){
        var txtArea = /textarea/i.test((e.target || e.srcElement).tagName);
        return txtArea || (e.keyCode || e.which || e.charCode || 0) !== 13;
        }
      document.querySelector('form').onkeypress = checkEnter;
    },
    addItem (e) {
      var that = this;
      that.$api.post(`${that.new_list_item_url}`, {list_item: that.newListItem}).then(function (response) {
        that.all_options.push({id: response.data.list_item.id, name: response.data.list_item.name });
        if (that.multiple_options) {
          that.selected.push({id: response.data.list_item.id, name: response.data.list_item.name });
        } else {
          that.selected = {id: response.data.list_item.id, name: response.data.list_item.name };
        }
        that.setValues()
        that.newListItem = "";
        that.isAdd = false;
      }).catch(error => {
        that.newListItem = "";
        that.isAdd = false;
        flash(error.response.data[0],'error')
      })
    }
  },
  data: function () {
    return {
      all_options: this.options,
      isAdd: false,
      newListItem: "",
      value: [],
      selected: [],
    }
  }
}
</script>
