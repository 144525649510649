<template>
  <div class='wizard'>

    <header class='wizard__header wizard__header--invisible'>
      <a href='/admin/imports' class='button button--large button--secondary'>Close & Return</a>
    </header>
    <div class='wizard__container'>
      <h1 class='wizard__heading'>Step 1. Upload File to Import</h1>
      <div class='alert'>
        Need a template? Download one from the imports page
        <a href='/admin/imports' class='button button--primary'>Go Back & Download Template</a>
      </div>
      <form @submit.prevent="submitForm" class='wizard__form'>
        <div class='form-group'>
          <label for='import_type'>Import Type</label>
          <select id='import_type' v-model='import_type'>
            <option value='Companies'>Companies</option>
            <option value='Knowledge Base Articles'>Knowledge Base Articles</option>
            <option value='Passwords'>Passwords</option>
            <option value='BlankAssetLayout'>Create a New Asset Layout</option>
            <option v-for='asset_layout in asset_layouts' :value='asset_layout[0]'>{{asset_layout[1]}}</option>
          </select>
        </div>

        <div class='alert' v-if='import_type == "Companies"'>Companies match on name. Don't import companies that will be synced through an integration.</div>
        <div class='alert' v-if='import_type == "Knowledge Base Articles"'>Knowledge Base Articles do not match, they will always create a new Article</div>
        <div class='alert' v-if='import_type == "Passwords"'>Passwords do not match, they will always create a new Password</div>
        <div class='alert' v-if='import_type == "BlankAssetLayout"'>This will create a new Asset Layout, and then create new Assets in the Asset Layout. All fields will be text, but this can be changed later by using the Asset Layout Wizard. If the Asset's Company name is not present, or a company is not found matching the name, then the Asset will not be created. Don't import assets that will be synced through an integration.</div>
        <div class='alert' v-if='import_type != "Companies" && import_type != "Knowledge Base Articles" && import_type != "Passwords" && import_type != "BlankAssetLayout" '>This will creates new Assets in an existing Asset Layout. If there are validation rules that are not met, the Asset will not insert. If the Asset's Company name is not present, or a company is not found matching the name, then the Asset will not be created. Don't import assets that will be synced through an integration.</div>

        <div class='form-group' v-if='import_type == "BlankAssetLayout"'>
          <label for='asset_layout_name'>Name of Asset Layout</label>
          <input type='text' placeholder="e.g. Network Devices" id='asset_layout_name' v-model='asset_layout_name'>
        </div>
        <div class='form-group'>
          <label for='file'>File for Import</label>
          <input id='file' type="file" @change="filesChange" accept=".csv" class="input-file">
        </div>
        <ul class='importer__errors'>
          <li v-for='error in errors'>Row {{error.row }}: {{error.message}}</li>
        </ul>
      </form>
      <div v-if='ready'>
        <h1 class='wizard__heading'>Step 2. Preview Import</h1>
        <preview :data="data" :meta="meta"></preview>
        <h1 class='wizard__heading'>Step 3. Start Importing</h1>
        <div class='alert alert--success' v-if='successfulSubmit'>
          Your Import has Started!
          <a href='/admin/imports' class='button button--primary'>View Imports</a>
        </div>
        <a href='#' v-if='!successfulSubmit' @click.prevent.stop="submitForm" class='button button--large button--success'>Start Import</a>
      </div>

    </div>
  </div>
</template>

<script>
  import Papa from "papaparse";
  import Preview from "./Preview.vue"

  export default {
    props: ["asset_layouts", "url"],
    components: {
      Preview
    },
    methods: {
      submitForm () {
        var that = this;
        that.$api.post(that.url, {
          import: {
            import_type: that.import_type,
            data: that.data,
            asset_layout_name: that.asset_layout_name,
            matching: that.matching
          }
        }).then(function (response) {
          that.successfulSubmit = true;
        }).catch(error => {
          console.log(error)
        })
      },
      filesChange (e) {
        var that = this;
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length)
          return;
        that.file = files[0]

        Papa.parse(this.file,
          {
            delimiter: "",  // auto-detect
            newline: "",  // auto-detect
            quoteChar: '"',
            escapeChar: '"',
            header: true,
            transformHeader: undefined,
            dynamicTyping: false,
            preview: 0,
            encoding: "",
            worker: false,
            comments: false,
            step: undefined,
            complete: function(results, file) {
              that.data = results.data;
              that.meta = results.meta;
              that.errors = results.errors;
              that.ready = true;
            },
            error: undefined,
            download: false,
            downloadRequestHeaders: undefined,
            skipEmptyLines: true,
            chunk: undefined,
            fastMode: undefined,
            beforeFirstChunk: undefined,
            withCredentials: undefined,
            transform: undefined,
            delimitersToGuess: [',', '\t', '|', ';', Papa.RECORD_SEP, Papa.UNIT_SEP]
          })
      }
    },
    data () {
      return {
        slide: 0,
        file: '',
        successfulSubmit: false,
        meta: [],
        ready: false,
        fileUploaded: false,
        data: [],
        import_type: "Companies",
        errors: [],
        asset_layout_name: ""
      }
    }
  }
</script>

<style scoped>

</style>./Importer.vue
