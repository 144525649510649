import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import App from "../components/FolderChooser.vue";

export default class extends Controller {
  connect() {

    const el = this.element;
    this.vueInstance = new Vue({
      el,
      render: h => h(App, {
        props: {
          folders: JSON.parse(el.dataset.folders),
          name: el.dataset.name,
          value: el.dataset.value,
          existing_name: el.dataset.existingName
        }
      })
    });
  }
}
