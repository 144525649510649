import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import axios from "axios";
import App from "../components/SecureNoter.vue";

export default class extends Controller {
  connect() {
    const el = this.element;

    Vue.use({
      install (Vue) {
        Vue.prototype.$api = axios.create({
          headers: {
            'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
          }
        })
      }
    })
    this.vueInstance = new Vue({
      el,
      render: h => h(App, {
        props: {
          dates: JSON.parse(el.dataset.dates),
          base_url: el.dataset.baseUrl,
          post_url: el.dataset.postUrl,
          company_id: el.dataset.companyId
        }
      })
    });
  }
}
