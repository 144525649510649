import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import axios from "axios";
import App from "../components/ArticleWriter.vue";

export default class extends Controller {
  connect() {
    const el = this.element;

    Vue.use({
          install (Vue) {
          Vue.prototype.$api = axios.create({
            headers: {
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
          })
        }
      })
    this.vueInstance = new Vue({
      el,
      render: h => h(App, {
        props: {
          name: el.dataset.name,
          prename: el.dataset.prename,
          id: el.dataset.id,
          chatgpt_url: el.dataset.chatgptUrl,
          chatgpt_available: el.dataset.chatgptAvailable === "true",
          folder_existing_name: el.dataset.folderExistingName,
          folder_value: el.dataset.folderValue,
          active_user_name: el.dataset.activeUserName,
          draft_folder_existing_name: el.dataset.draftFolderExistingName,
          draft_folder_value: el.dataset.draftFolderValue,
          folders: JSON.parse(el.dataset.folders),
          submit_url: el.dataset.submitUrl,
          takeover_url: el.dataset.takeoverUrl,
          close_url: el.dataset.closeUrl,
          autosave_url: el.dataset.autosaveUrl,
          precontent: el.dataset.precontent,
          dark_mode: el.dataset.darkMode === "true",
          draft_title: el.dataset.draftTitle,
          last_saved: el.dataset.lastSaved,
          last_updated: el.dataset.lastUpdated,
          article_url: el.dataset.articleUrl,
          draft_content: el.dataset.draftContent,
          has_draft: el.dataset.hasDraft === "true"
        }
      })
    });
  }
}
