import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from 'stimulus-use'
export default class extends Controller {
  static targets = ["header", "editForm", "newNameInput", "nameDisplay", "nameStatic"]

  connect() {
    this.isEdit = false;
    this.newName = this.nameDisplayTarget.textContent;
    useClickOutside(this)
  }

  toggleEdit(event) {
    event.preventDefault();
    this.isEdit = !this.isEdit;
    this.updateUI();
  }

  clickOutside(event) {
    if (this.isEdit) {
      this.isEdit = false;
      this.updateUI();
    }
  }

  updateUI() {
    this.editFormTarget.style.display = this.isEdit ? "block" : "none";
    this.headerTarget.style.display = !this.isEdit ? "block" : "none";
    this.nameDisplayTarget.textContent = this.newName;
    this.newNameInputTarget.focus()
  }
}
