import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import axios from "axios";
import App from "../components/StateRegionPicker.vue";

export default class extends Controller {
  connect() {
    const el = this.element;

    this.vueInstance = new Vue({
      el,
      render: h => h(App, {
        props: {
          country_name: el.dataset.countryName,
          country_value: el.dataset.countryValue,
          region_name: el.dataset.regionName,
          region_value: el.dataset.regionValue
        }
      })
    });
  }
}
