import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import App from "../components/Searcher.vue";

export default class extends Controller {
  connect() {

    if (this.vueInstance) {
      this.vueInstance.$destroy();
    }

    const el = this.element;
    this.vueInstance = new Vue({
      el,
      render: h => h(App, {
        props: {
          companyName: el.dataset.companyName,
          companyId: parseInt(el.dataset.companyId),
          structureName: el.dataset.structureName,
          companyQuickHop: el.dataset.companyQuickHop === "true",
          defaultComprehensiveSearch: el.dataset.defaultComprehensiveSearch === "true",
        }
      })
    });
  }
}
