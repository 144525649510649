
import Vue from "vue/dist/vue.esm";
import Vuex from 'vuex'
import globals from './modules/globals'
import procedures from './modules/procedures'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const store = new Vuex.Store({
  modules: {
    globals,
    procedures
  },
  strict: debug
})

export default store;