import { Controller } from "@hotwired/stimulus"
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

export default class extends Controller {
  connect() {
    var that = this;
    that.lon = that.element.dataset.lon;
    that.lat = that.element.dataset.lat;
    that.mainLocation = that.element.dataset.mainLocation;
    that.mapbox_style = that.element.dataset.mapboxStyle;
    mapboxgl.accessToken = that.element.dataset.accessToken;

    const containerId = that.element.id;
    var map = new mapboxgl.Map({
      container: containerId,
      style: that.mapbox_style ? that.mapbox_style : 'mapbox://styles/mapbox/streets-v12',
      center: [that.lon, that.lat],
      zoom: 13,
      attributionControl: false
    }).addControl(new mapboxgl.NavigationControl());

    // Create a marker for the main company address
    new mapboxgl.Marker()
      .setLngLat([that.lon, that.lat])
      .setPopup(
          new mapboxgl.Popup({ offset: 10 })
            .setHTML(
              `<h3>${that.mainLocation}</h3>`
            )
        )
      .addTo(map);

    // Add markers for additional locations
    const locationsData = that.element.dataset.locations;
    function generatePopupHTML(location) {
      return `
        <a href="${location.asset_url}">
          ${location.asset_name}
        </a>
        <p>
          ${location.asset_field.address_data.address_line_1}
          ${location.asset_field.address_data.address_line_2}
          ${location.asset_field.address_data.city},
          ${location.asset_field.address_data.state}
          ${location.asset_field.address_data.zip}
          ${location.asset_field.address_data.country_name}
        </p>
      `;
    }

    var bounds = new mapboxgl.LngLatBounds();
    var locationsCount = 1;

    if (locationsData) {
      const locations = JSON.parse(locationsData);
      locations.forEach(location => {
        new mapboxgl.Marker()
          .setLngLat([location.lon, location.lat])
          .setPopup(
            new mapboxgl.Popup({ offset: 10 })
            .setHTML(generatePopupHTML(location))
            )
          .addTo(map);

        bounds.extend([location.lon, location.lat]);
        locationsCount++;
      });
    }

    bounds.extend([that.lon, that.lat]);

    if (locationsCount > 1) {
      map.fitBounds(bounds, {
        padding: 50,
        maxZoom: 15,
        duration: 500
      });
    }

    const geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      mapboxgl: mapboxgl
    });
  }
}
