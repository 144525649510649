// check-all_controller.js

import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["mainCheckbox", "selectContainer"];

  toggle(event) {
      event.preventDefault();
      event.stopPropagation();

      const isChecked = this.mainCheckboxTarget.checked;
      const parentForm = this.mainCheckboxTarget.closest('div');
      const boxes = this.selectContainerTarget.querySelectorAll("input[type='checkbox']");

      boxes.forEach(box => {
        box.checked = isChecked;
      });
  }
}
