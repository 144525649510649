import { Controller } from "@hotwired/stimulus"
import * as ClipboardFunctions from "../lib/ClipboardFunctions.js";
import flash from "../lib/flashes.js";

export default class extends Controller {
  static targets = ["input", "icon"]
  copy (event) {
    event.preventDefault();
    ClipboardFunctions.copyText(this.inputTarget.value);
    this.iconTarget.classList.remove("fa-copy");
    this.iconTarget.classList.add("fa-check");
    this.iconTarget.classList.add("success");

    setTimeout(() => {
      this.iconTarget.classList.remove("fa-check");
      this.iconTarget.classList.add("fa-copy");
      this.iconTarget.classList.remove("success");
    }, 3000);

    flash("Copied to clipboard", "success")
  }
}
