<template>
  <div>
    <div class='asset-type-sorter'>
      <header style='display: inline-block;margin-top: .5rem;'>
        <a href='#' v-show='!isShown' @click.prevent.stop='isShown = true' class='button button--secondary'>Rearrange Fields</a>
        <a href='#' v-show='isShown' @click.prevent.stop='isShown = false' class='button button--secondary'>Cancel Rearranging</a>
      </header>
      <main v-show='saved'><p>Saved rearrangements. Refresh the page to show changes.</p></main>
      <main v-show='isShown'>
        <header>
          <a href='#' @click.prevent.stop='savePosition' class='button button--primary'>Save order</a>
        </header>
        <ul>
          <draggable v-model="fields" @end="onSortEnd">
            <transition-group>
              <li v-for='(field, index) in fields'  :key="'drag' + index"><i class="fas fa-bars"></i>{{index + 1}}: {{field.label}}</li>
            </transition-group>
          </draggable>

        </ul>
        <footer>
          <a href='#' @click.prevent.stop='savePosition' class='button button--primary'>Save order</a>
        </footer>
      </main>
    </div>
  </div>

</template>

<script>
import draggable from 'vuedraggable'
export default {
  props: ["url", "update_url"],
  components: {
    draggable
  },
  data: function () {
    return {
     isShown: false,
     saved: false,
     fields: []
    }
  },
  mounted () {
    var that = this;
    fetch(that.url)
    .then((resp) => resp.json())
    .then(function(data) {
      that.fields = data.asset_layout.fields;
    })
  },
  methods: {
    onSortEnd () {
      this.fields.forEach(function (item, index) {
        item.position = index + 1
        console.log(`new position is ${item.position}`)
      })
    },
    savePosition () {
      var that = this;
      that.$api.put(that.update_url, {"asset_layout": {"asset_layout_field_attributes": that.fields }})
      .then(function (response) {
        that.asset_layout = response.data.new_asset_layout
        that.saved = true
        that.isShown = false
      }).catch(error => {
        alert("Something went wrong...")
      })
    }
  }
}
</script>

