import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "hideable", "icon" ]
  static values = { display: { type: String, default: "block" }, localStorageName: { type: String }}

  connect () {

    if (this.localStorageNameValue) {
      if (localStorage.getItem(this.localStorageNameValue) == "true") {
        this.hide(this.hideableTargets[0])
      }
    }
  }

  show (el) {
    if (this.hasIconTarget) {
      this.iconTarget.classList.remove("fa-chevron-right");
      this.iconTarget.classList.add("fa-chevron-down");
    }
    el.style.display = this.displayValue;
  }

  hide (el) {
    el.style.display = "none";
    if (this.hasIconTarget) {
      this.iconTarget.classList.add("fa-chevron-right");
      this.iconTarget.classList.remove("fa-chevron-down");
    }
  }

  toggleTargets() {
    let compStyles = window.getComputedStyle(this.hideableTargets[0]);

    this.hideableTargets.forEach((el) => {
      if (el.style.display == "none" || compStyles.getPropertyValue("display") == "none") {
        this.show(el)

        if (this.localStorageNameValue) {
          localStorage.setItem(this.localStorageNameValue, false)
          console.log(localStorage.getItem(this.localStorageNameValue));
        }
      } else {
        this.hide(el)

        if (this.localStorageNameValue) {
          localStorage.setItem(this.localStorageNameValue, true)
        }
      }
    });
  }
}
