
<template>
  <div>
    <form class='form' v-on:keydown.enter.prevent>
      <div class='form-group' style='width: 100%'>
        <vue-dropzone ref="myVueDropzone" @vdropzone-file-added='vOnFileAdd' @vdropzone-success='vsuccess' id="dropzone" :options='dropzoneOptions'></vue-dropzone>
      </div>
      <div v-if='canUpload' class='form-group'>
        <label for='fileName'>Name of File</label>
        <input id='fileName' type='text' v-model='dropzoneOptions.params.fileName' ref='fileNameInput' />
      </div>
      <div style='display: flex;justify-content: space-between'>
        <div class='button-group'>
        </div>
        <div class='button-group button-group--align-right'>
          <a href='#' v-if='canUpload' @click.prevent.stop='vTriggerUpload' class='button button--success button--large'>Upload PDF</a>
        </div>
      </div>

    </form>
  </div>
</template>

<script>
import vue2Dropzone from 'vue2-dropzone'

export default {
  props: ["upload_path"],
  components: {
    vueDropzone: vue2Dropzone
  },
  data () {
    return {
      fileName: "",
      dropzoneOptions: {
          url: this.upload_path,
          timeout: 90000,
          parallelUploads: 1,
          maxFiles: 1,
          acceptedFiles: "application/pdf",
          maxFilesize: this.max_file_size,
          autoProcessQueue: false,
          params: {
            fileName: ""
          }
      },
      canUpload: false,
      name: "",
    }
  },
  methods: {
    vOnFileAdd ( file, response) {
      var that = this;
      this.dropzoneOptions.params.fileName = file.name;
      this.canUpload = true
      this.$nextTick(() => this.$refs['fileNameInput'].focus())
    },
    vsuccess (file, response) {
      window.location = response.url
    },
    vTriggerUpload () {
      this.$refs.myVueDropzone.processQueue()
    }
  }
}
</script>
