
import flash from '../../lib/flashes';

const state = {
  showEdit: false,
  showDescription: false,
  openProcessView: -1,
  openProcessEdit: -1,
  openProcessDescriptionEdit: false,
  openCompletionEdit: -1,
  setUrlState: false,
  openAssignerEdit: -1,
  openDaterEdit: -1,
  openPriorityEdit: -1,
  showFirework: false,
  procedure: {
    name: "",
    description: "",
    procedure_tasks_attributes: []
  },
  deleted: []
}

const getters = {
  openTask (state)  {
     return state.procedure.procedure_tasks_attributes[state.openProcessView];
  },
  procedureJSON (state) {
    return JSON.stringify(state.procedure)
  },
  procedureCompletionPercentage (state, getters) {
    if (getters.tasksLength == 0) {
      return "0%"
    } else {
      return Math.round((state.procedure.completed / getters.tasksLength) * 100)  + "%"
    }
  },
  procedureStatus (state, getters) {
    if (state.procedure.completed == 0) {
      return "Not Started"
    } else if (state.procedure.completed == getters.tasksLength ) {
      return "Completed"
    } else {
      return "In Progress"
    }
  },
  openEdit (state)  {
    if (state.openProcessView != -1) {
     return state.procedure.procedure_tasks_attributes[state.openProcessEdit];
    } else {
      return null;
    }
  },
  tasksLength (state) {
    return state.procedure.procedure_tasks_attributes.filter(task => !task._deleted).length
  }
}

const actions = {
  setState ({ commit, state}, payload) {
    commit('SET_STATE', payload)
  },
  toggleEdit ({commit, state}) {
    commit('TOGGLE_EDIT')
  },
  toggleDescription ({commit, state}) {
    commit('TOGGLE_DESCRIPTION')
  },
  toggleOpenProcessView ({commit, state}, payload) {
    commit('TOGGLE_OPEN_PROCESS_VIEW', payload)
  },
  toggleOpenCompletionEdit ({commit, state}, payload) {
    commit('TOGGLE_OPEN_COMPLETION_EDIT', payload)
  },
  toggleOpenProcessDescriptionEdit ({commit, state}) {
    commit('TOGGLE_OPEN_PROCESS_DESCRIPTION_EDIT')
  },
  toggleOpenAssignerEdit ({commit, state}, payload) {
    commit('TOGGLE_OPEN_ASSIGNER_EDIT', payload)
  },
  toggleOpenDaterEdit ({commit, state}, payload) {
    commit('TOGGLE_OPEN_DATER_EDIT', payload)
  },
  toggleOpenPriorityEdit ({commit, state}, payload) {
    commit('TOGGLE_OPEN_PRIORITY_EDIT', payload)
  },
  submitCompletionNote ({commit, state}, payload) {
    var task_id = payload[0]
    var notes = payload[1]
    this._vm.$api.put(`${state.url}/update_completion_notes`, {
      task_id: task_id,
      completion_notes: notes
    }).then(function (response) {
      commit('SET_PROCEDURE', [response.data,null])
      commit('SUBMIT_COMPLETION_NOTE')
    })
    .catch(function (error) {
      const { response } = error;
      const { request, ...errorObject } = response;
      var errorResp = errorObject.data;
      // const response = error.response
      flash(`Error: ${errorResp[0]}`, "error")
    })
  },
  submitAssignment ({commit, state}, payload) {
    var task_id = payload[0]
    var assigned_users = payload[1]
    this._vm.$api.put(`${state.url}/update_assignment`, {
      task_id: task_id,
      assigned_users: assigned_users
    }).then(function (response) {
      commit('SET_PROCEDURE', [response.data, null])
      commit('SUBMIT_ASSIGNMENT')
    })
    .catch(function (error) {
      const { response } = error;
      const { request, ...errorObject } = response;
      var errorResp = errorObject.data;
      // const response = error.response
      flash(`Error: ${errorResp[0]}`, "error")
    })
  },
  submitDate ({commit, state}, payload) {
    var task_id = payload[0]
    var due_date = payload[1]
    this._vm.$api.put(`${state.url}/update_due_date`, {
      task_id: task_id,
      due_date: due_date
    }).then(function (response) {
      commit('SET_PROCEDURE', [response.data, null])
      commit('SUBMIT_DATE')
    })
    .catch(function (error) {
      const { response } = error;
      const { request, ...errorObject } = response;
      var errorResp = errorObject.data;
      // const response = error.response
      flash(`Error: ${errorResp[0]}`, "error")
    })
  },
  submitPriority ({commit, state}, payload) {
    var task_id = payload[0]
    var priority = payload[1]

    this._vm.$api.put(`${state.url}/update_priority`, {
      task_id: task_id,
      priority: priority
    }).then(function (response) {
      commit('SET_PROCEDURE', [response.data, null])
      commit('SUBMIT_PRIORITY')
    })
    .catch(function (error) {
      const { response } = error;
      const { request, ...errorObject } = response;
      var errorResp = errorObject.data;
      // const response = error.response
      flash(`Error: ${errorResp[0]}`, "error")
    })
  },
  toggleOpenProcessEdit ({commit, state}, payload) {
    commit('TOGGLE_OPEN_PROCESS_EDIT', payload)
  },
  updateProcedureName ({commit, state}, payload) {
    commit('UPDATE_PROCEDURE_NAME', payload)
  },
  updateProcedureDescription ({commit, state}, payload) {
    commit('UPDATE_PROCEDURE_DESCRIPTION', payload)
  },
  updateTaskName ({commit, state}, payload) {
    commit('UPDATE_TASK_NAME', payload)
  },
  update_tasks ({commit, state}, value) {
    commit('UPDATE_TASKS', value)
  },
  fetch ({ commit, state}, payload) {
    this._vm.$api.get(`${state.url}.json`).then(function (response) {
      commit('SET_PROCEDURE', [response.data, payload])
    })
    .catch(function (response) {
      flash("Something went wrong!", "error")
    })
  },
  check ({commit, state}, payload) {
    var id = payload[0]
    var index = payload[1]
    var completed = payload[2]

    if (completed) {
      this._vm.$api.put(`${state.url}/uncheck`, {
        task_id: id
      }).then(function (response) {
        commit('SET_PROCEDURE', [response.data,null])
      })
    } else {
      this._vm.$api.put(`${state.url}/check`, {
        task_id: id
      }).then(function (response) {
        commit('SHOW_FIREWORK');
        setTimeout(() => commit('HIDE_FIREWORK'), 1200)
        commit('SET_PROCEDURE', [response.data, null])
      })
    }
  },
  addTask ({commit, state}) {
    commit('ADD_TASK')
  },
  removeTask ({commit, state}, payload) {
    commit('REMOVE_TASK', payload)
  },
  resort ({commit, state}) {
    commit('RESORT')
  },
  submitForm ({commit, state}) {
    var newProcedure = state.procedure;

    state.deleted.forEach(function(item){
      newProcedure.procedure_tasks_attributes.push({
        id: item,
        name: "",
        _destroy: true
      })
    })

    this._vm.$api.put(`${state.url}.json`, {
      procedure: newProcedure
    }).then(function (response) {
      commit('HANDLE_SUBMIT_SUCCESS', response.data)
    })
    .catch(function (error) {
      const { response } = error;
      const { request, ...errorObject } = response;
      var errorResp = errorObject.data;
      // const response = error.response
      flash(`Error: ${errorResp[0]}`, "error")
    })
  },
  updateTaskDescription ({commit, state}, payload) {
    commit('UPDATE_TASK_DESCRIPTION', [payload[0], payload[1]])
  }
}

const mutations = {
  SET_STATE (state, payload) {
    state.url = payload[0]
    state.can = payload[1]
    state.template = payload[2]
  },
  UPDATE_TASK_DESCRIPTION (state, payload) {
    var index = payload[0]
    var description = payload[1]
    state.procedure.procedure_tasks_attributes[index].description = description
  },
  TOGGLE_DESCRIPTION (state, payload) {
    state.showDescription = !state.showDescription
  },
  TOGGLE_OPEN_PROCESS_DESCRIPTION_EDIT (state, payload) {
    state.openProcessDescriptionEdit = !state.openProcessDescriptionEdit
  },
  SUBMIT_COMPLETION_NOTE (state, payload) {
    state.openCompletionEdit = -1
  },
  SHOW_FIREWORK (state) {
    state.showFirework = true
  },
  HIDE_FIREWORK (state) {
    state.showFirework = false
  },
  SUBMIT_ASSIGNMENT (state, payload) {
    state.openAssignerEdit = -1
  },
  SUBMIT_DATE (state, payload) {
    state.openDaterEdit = -1
  },
  SUBMIT_PRIORITY (state, payload) {
    state.openPriorityEdit = -1
  },
  TOGGLE_OPEN_PROCESS_VIEW (state, payload) {
    if (state.openProcessView == -1) { // If none are open, then open
      state.openProcessView = payload
    } else if (state.openProcessView == payload) { // If opening exact same one, close it.
      state.openProcessView = -1;
    }else {
      state.openProcessView = payload
    }
  },
  TOGGLE_OPEN_PROCESS_EDIT (state, payload) {
    if (state.openProcessEdit == -1) { // If none are open, then open
      state.openProcessEdit = payload
    } else if (state.openProcessEdit == payload) {
      state.openProcessEdit = -1
    } else {
      state.openProcessEdit = payload
    }

  },
  TOGGLE_OPEN_COMPLETION_EDIT (state, payload) {
    if (state.openCompletionEdit == "") { // If none are open, then open
      state.openCompletionEdit = payload
    } else if (state.openCompletionEdit == payload) {
      state.openCompletionEdit = ""
    } else {
      state.openCompletionEdit = payload
    }
  },
  TOGGLE_OPEN_ASSIGNER_EDIT (state, payload) {
    if (state.openAssignerEdit == "") { // If none are open, then open
      state.openAssignerEdit = payload
    } else if (state.openAssignerEdit == payload) {
      state.openAssignerEdit = ""
    } else {
      state.openAssignerEdit = payload
    }
  },
  TOGGLE_OPEN_DATER_EDIT (state, payload) {
    if (state.openDaterEdit == "") { // If none are open, then open
      state.openDaterEdit = payload
    } else if (state.openDaterEdit == payload) {
      state.openDaterEdit = ""
    } else {
      state.openDaterEdit = payload
    }
  },
  TOGGLE_OPEN_PRIORITY_EDIT (state, payload) {
    if (state.openPriorityEdit == "") { // If none are open, then open
      state.openPriorityEdit = payload
    } else if (state.openPriorityEdit == payload) {
      state.openPriorityEdit = ""
    } else {
      state.openPriorityEdit = payload
    }
  },
  UPDATE_TASK_NAME (state, payload) {
    var index = payload[0]
    var value = payload[1]
    state.procedure.procedure_tasks_attributes[index].name = value
  },
  TOGGLE_EDIT (state) {
    state.showEdit = !state.showEdit;
    state.showBulk = false;
    state.openProcessEdit = -1
    state.openProcessView = -1
  },
  UPDATE_TASKS (state, value) {
    state.procedure.procedure_tasks_attributes = value;
  },
  HANDLE_SUBMIT_SUCCESS (state, data) {
    state.procedure = data.procedure;
    state.showEdit = false;
    flash("Process Updated!", "success")
    //window.location.reload();
  },
  UPDATE_PROCEDURE_NAME (state, value) {
    state.procedure.name = value
  },
  UPDATE_PROCEDURE_DESCRIPTION (state, value) {
    state.procedure.description = value
  },
  ADD_TASK (state) {
    state.procedure.procedure_tasks_attributes.push({
      id: "",
      name: "Untitled Task",
      position: state.procedure.procedure_tasks_attributes.length + 1,
      description: "",
      _destroy: false
    })
    state.openProcessEdit = (state.procedure.procedure_tasks_attributes.filter(task => !task._deleted).length) - 1
  },
  REMOVE_TASK (state, payload) {
    var index = payload[0]
    var id = payload[1]

    if (id) {
      state.deleted.push(id) // Add to deleted
    }

    state.openProcessEdit = -1
    state.procedure.procedure_tasks_attributes.splice(index, 1) // Remove from visible list

    state.procedure.procedure_tasks_attributes.forEach(function (item, index) {
      item.position = index + 1 // Make
    })
  },
  RESORT (state) {
    state.procedure.procedure_tasks_attributes.forEach(function (item, index) {
      item.position = index + 1
    })
  },
  SET_PROCEDURE (state, payload) {
    state.procedure = payload[0].procedure

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let taskId = urlParams.get('task')

    if (taskId && !state.setUrlState) {
      taskId = parseInt(taskId)
      const index = state.procedure.procedure_tasks_attributes.findIndex(p=>p.id === taskId)
      state.openProcessView = index
      state.setUrlState = true
    }
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
