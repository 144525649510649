<template>
  <div class='dashboard_item_sorter'>

    <draggable v-model="fields" @end="onSortEnd">
      <transition-group>
        <div v-for='(field, index) in fields'  :key="'drag' + index"
          :class='{"dashboard_item_sorter__item": true, "dashboard_item_sorter__item--muted": field.hidden}'>
          <article>
            <h1><i class='fas fa-bars icon'></i><a data-turbo-frame='_top' :href='field.url'>{{field.name}}</a></h1>
          </article>
          <!-- <a href='#' @click.prevent.stop='deleteField(field.unpin_url)' class='button button--danger'>Remove Favorite</a> -->
        </div>
      </transition-group>
    </draggable>

  </div>
</template>

<script>
const axios = require('axios');
import draggable from 'vuedraggable'
export default {
  props: ["fetch_url", "update_url"],
  data: function () {
    return {
      fields: []
    }
  },
  components: {
    draggable
  },
  mounted () {
    this.grabFields()
  },
  methods: {
    deleteField (unpin_url) {
      var that = this;
      that.$api.delete(unpin_url)
      .then(function (response) {
        that.grabFields()
      }).catch(error => {
        alert("Something went wrong...")
      })
    },
    grabFields () {
      var that = this;

      fetch(that.fetch_url)
      .then((resp) => resp.json())
      .then(function(data) {
        that.fields = data
      })
    },
    onSortEnd () {
      this.fields.forEach(function (item, index) {
        item.position = index + 1
      })
      this.savePosition()
    },
    savePosition () {
      var that = this;
      that.$api.post(that.update_url, {"fields": that.fields})
      .then(function (response) {
        that.grabFields()
      }).catch(error => {
        alert("Something went wrong...")
      })
    }
  }
}
</script>

