import { Controller } from "@hotwired/stimulus";
import Vue from "vue/dist/vue.esm";
import axios from "axios";
import App from "../components/Relater.vue";

export default class extends Controller {
  connect() {
    const el = this.element;

    Vue.use({
          install (Vue) {
          Vue.prototype.$api = axios.create({
            headers: {
              'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
          })
        }
      })
    this.vueInstance = new Vue({
      el,
      render: h => h(App, {
        props: {
          structure_name: el.dataset.structureName,
          company_name: el.dataset.companyName,
          company_id: el.dataset.companyId,
          only_company: el.dataset.onlyCompany === "true",
          default: el.dataset.default,
          url: el.dataset.url,
          id: el.dataset.id,
          can: el.dataset.can === "true",
          type: el.dataset.type
        }
      })
    });
  }
}
